import * as Sentry from '@sentry/browser'
import React, { Component, ErrorInfo } from 'react'
import { isBrowser } from 'src/common/utils'

export class ErrorBoundary extends Component<
  Record<string, unknown>,
  { hasError: boolean; error?: Error; sentryEventId?: string }
> {
  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
    Sentry.withScope(scope => {
      scope.setExtras({ ...errorInfo })
      const eventId = Sentry.captureException(error)

      this.setState({
        hasError: true,
        sentryEventId: eventId,
      })
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="mx-auto prose">
          <h4 className="mt-48 text-center">Something went wrong</h4>
          <div className="flex items-center justify-center my-4">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              onClick={async () => {
                requestAnimationFrame(() => {
                  if (isBrowser()) window.location.reload()
                })
              }}
            >
              Reload app
            </button>
          </div>
        </div>
      )
    }

    return this.props.children as any
  }
}
