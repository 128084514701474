import React, { FC } from 'react'

interface LogoProps {
  className?: string
}

export const Logo: FC<LogoProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920.05 691.58"
    >
      <defs>
        <linearGradient
          id="logo-grad-1"
          x1="6519.24"
          y1="3764.21"
          x2="6519.24"
          y2="3551.42"
          gradientTransform="translate(-1465.04 -7066.34) rotate(45)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f5876b" />
          <stop offset="1" stopColor="#f5b68f" />
        </linearGradient>
        <linearGradient id="logo-grad-2" x1="164.9" y1="524.28" x2="390.8" y2="298.37" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ffb93c" />
          <stop offset="1" stopColor="#ffd33c" />
        </linearGradient>
        <linearGradient id="logo-grad-3" x1="164.99" y1="524.24" x2="553.17" y2="136.06" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#7bd6c7" />
          <stop offset="1" stopColor="#bdf4e7" />
        </linearGradient>
      </defs>
      <g id="Logo">
        <path
          className="fill-neutral-900 dark:fill-neutral-200"
          d="M877.56,236.59c-45.83-.4-46.31-67.56,0-67.78C922.1,169,921.6,236.24,877.56,236.59Zm24.27,32.52V516H851.45V269.11Z"
        />
        <path
          className="fill-neutral-900 dark:fill-neutral-200"
          d="M1017.25,516H966.87V269.11h49.47v34.81c15.58-27.33,46.15-41.44,78.32-41.22,135.38,3.89,89.42,164.39,97.1,253.29h-50.84V380c.39-39.9-19.33-70.36-59.09-70.53-39.48-.41-65.28,32.24-64.58,71.45Z"
        />
        <path
          className="fill-neutral-900 dark:fill-neutral-200"
          d="M1326.87,521.94c-38.47.1-75.09-18.16-94.81-51.75l32.51-29.32c17.46,30.62,62.88,50.06,92.53,28.63,19.16-14.92,13.39-40.27-9.62-50.15-39.79-17.76-103.34-33.64-100.31-87.49-.75-82.54,127.15-92.32,165.35-33L1383.2,330c-18.26-23.51-53.91-36.8-78.09-19.24-16.12,14.42-9.73,38.47,11.68,46.26,15.88,6.68,49.85,20.55,60,26.11C1459.36,425.28,1409.56,527.54,1326.87,521.94Z"
        />
        <path
          className="fill-neutral-900 dark:fill-neutral-200"
          d="M1653,301.17,1518.31,472.93h138.78V516H1449.61V482.55l135.12-169.93H1456.94V269.57h196Z"
        />
        <path
          className="fill-neutral-900 dark:fill-neutral-200"
          d="M1810.07,521.94c-145.09-1.18-101.77-158-108.1-252.83h50.39v145.2c-.31,36,21.16,61.27,57.71,60.92,37.43.2,58-23.72,57.71-60.92V269.11h50.84C1912.19,364.18,1956,522.37,1810.07,521.94Z"
        />
        <path
          className="fill-[url(#logo-grad-1)]"
          d="M634.61,206.25c-102,98.6-251.23-50.64-152.63-152.64C584-45,733.22,104.24,634.61,206.25Z"
        />
        <path
          className="fill-[url(#logo-grad-2)]"
          d="M96,217.48c-128,2.2-128,192.57,0,194.74H276c8.56,86.06-41.47,277.27,97.37,279.36,139.26-2.63,88.6-193,97.36-279.36-85-126.48-63.85-112.63-194.73-194.74Z"
        />
        <path
          className="fill-[url(#logo-grad-3)]"
          d="M649.56,235.22C520.24,345.77,342.82,169.68,452.25,39.54,402-35.91,272.71,4.1,276,96.54V217.48L470.74,412.22H593.66C687.48,414.17,726.43,286.33,649.56,235.22Z"
        />
      </g>
    </svg>
  )
}
