import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import identity from 'lodash/identity'
import React, { DragEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactFlowProvider, useStoreActions } from 'react-flow-renderer/nocss'
import ReactFlow, {
  ConnectionLineType,
  Controls,
  OnEdgeUpdateFunc,
  OnLoadParams,
  PanOnScrollMode,
  ReactFlowProps,
  removeElements,
  updateEdge,
} from 'react-flow-renderer/nocss'
import { updateFormDesign } from 'src/backend/update-form-design'
import { classNames, ElementsContext, FlowContext, isBrowser, nodeData } from 'src/common/utils'
import { addNewEdge } from 'src/common/utils'
import { Approved } from 'src/components/approved'
import { ChecklistQn } from 'src/components/checklist-qn'
import { ComponentLibrary } from 'src/components/component-library'
import { ConditionsQn } from 'src/components/conditions-qn'
import { DateInputQn } from 'src/components/date-input-qn'
import { Declined } from 'src/components/declined'
import { DropdownQn } from 'src/components/dropdown-qn'
import { EditorBackground } from 'src/components/editor-background'
import { EditorHeader } from 'src/components/editor-header'
import { EmailInputQn } from 'src/components/email-input-qn'
import { ErrorBoundary } from 'src/components/error-boundary'
import { FormPreview } from 'src/components/form-preview'
import { GroupQns } from 'src/components/group-qns'
import { InitialNode } from 'src/components/initial-node'
import { MultiSelectQn } from 'src/components/multi-select-qn'
import { NodeDetails } from 'src/components/node-details'
import { NumberInputQn } from 'src/components/number-input-qn'
import { PhoneInputQn } from 'src/components/phone-input-qn'
import { PrescriptionsQn } from 'src/components/prescriptions-qn'
import { SectionBody } from 'src/components/section-body'
import { SectionTitle } from 'src/components/section-title'
import { SelectableEdge } from 'src/components/selectable-edge'
import { Signature } from 'src/components/signature'
import { TemplateQns } from 'src/components/template-qns'
import { TextInputQn } from 'src/components/text-input-qn'
import Thumbnail from 'src/components/thumbnail'
import { YesNoQn } from 'src/components/yes-no-qn'
import { DesignType, GetFormDesignQuery, useGetFormDesignQuery } from 'src/graphql-schema'
import { useAutoSave } from 'src/hooks/use-auto-save'
import { usePreviewMode } from 'src/hooks/use-preview-mode'
import { DragDropData, EdgeMeta, FlowElements, isTemplateData, NodeMeta } from 'src/types'
import { v4 } from 'uuid'

const fitViewParams = { maxZoom: 1, minZoom: 1, padding: 24 }
const nodeTypes = {
  Initial: InitialNode,
  YesNoQn,
  DropdownQn,
  MultiSelectQn,
  ChecklistQn,
  TemplateQns,
  SectionTitle,
  SectionBody,
  TextInputQn,
  EmailInputQn,
  PhoneInputQn,
  DateInputQn,
  NumberInputQn,
  Declined,
  Approved,
  Signature,
  PrescriptionsQn,
  ConditionsQn,
  GroupQns,
}

const edgeTypes = {
  SelectableEdge,
}

let isResizing = false

const startResizing = () => (isResizing = true)
const stopResizing = () => (isResizing = false)

export function EditorBase({ design }: { design: GetFormDesignQuery['getFormDesign'] }) {
  const [title, setTitle] = useState('Application title')
  const [desc, setDesc] = useState('Application description')
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements)
  const [previewMode, setPreviewMode] = usePreviewMode(false)
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const sidebar = useRef<HTMLDivElement>(null)
  const [flowInstance, setFlowInstance] = useState<OnLoadParams<FlowElements> | null>(null)
  const [elements, setElements] = useState<FlowElements>(design?.config.elements || [])

  const designId = design?.id
  useAutoSave(elements, design?.id)

  const saveDesign = useCallback(() => {
    if (!designId || elements.length === 0) return
    const thumbnail = document.getElementById('thumbnail-preview')?.innerHTML
    console.log('saving design')
    updateFormDesign({ data: { id: designId, config: { elements, thumbnail } } }).catch(identity)
  }, [designId, elements])

  const debouncedSaveDesign = useMemo(() => debounce(saveDesign, 5000), [saveDesign])

  const isTemplate = design?.type === DesignType.Template
  const resize = useCallback((mouseMoveEvent: MouseEvent) => {
    if (isResizing && sidebar.current) {
      const width = window.innerWidth - mouseMoveEvent.clientX
      sidebar.current.style.width = `${width}px`
    }
  }, [])

  const onLoad = useCallback((instance: OnLoadParams<FlowElements>) => {
    setFlowInstance(instance)
    setTimeout(() => {
      instance.fitView(fitViewParams)
    }, 100)
  }, [])

  const onEdgeUpdate: OnEdgeUpdateFunc<EdgeMeta> = (oldEdge, newConnection) => {
    setElements(currentElements => updateEdge(oldEdge, newConnection, currentElements))
  }

  const onConnect: ReactFlowProps['onConnect'] = useCallback(params => {
    setElements(currentElements => addNewEdge(currentElements, params))
  }, [])

  const onElementsRemove = useCallback(
    (elementsToRemove: FlowElements) => setElements(els => removeElements(elementsToRemove, els)),
    [],
  )

  const onDragOver: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const onDrop: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    if (!reactFlowWrapper.current || !flowInstance) return
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    const data = JSON.parse(event.dataTransfer.getData('application/form-builder')) as DragDropData
    const { type } = data
    const position = flowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
    const nodeMetadata = isTemplateData(data) ? { ...data } : nodeData[type]
    const id = v4()
    const newNode = {
      id,
      type,
      position,
      data: {} as NodeMeta,
    }
    if (nodeMetadata) newNode.data = cloneDeep(nodeMetadata)
    newNode.data.id = id
    newNode.data.type = type

    setElements(currentElements => currentElements.concat([newNode]))
    setSelectedElements([newNode])
  }

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResizing)
      return () => {
        window.removeEventListener('mousemove', resize)
        window.removeEventListener('mouseup', stopResizing)
      }
    }
  }, [resize])

  return (
    <FlowContext.Provider value={flowInstance}>
      <ElementsContext.Provider value={{ elements, setElements }}>
        <div className="flex flex-col h-screen editor">
          <EditorHeader
            id={design?.id}
            name={design?.name}
            previewMode={previewMode}
            setPreviewMode={setPreviewMode}
            elements={elements}
          />
          <div className="flex flex-1 overflow-hidden">
            <aside
              id="components-library"
              className="flex flex-col flex-shrink-0 w-64 px-4 bg-white border-r dark:bg-neutral-900 border-r-neutral-200 dark:border-r-neutral-800"
            >
              <ComponentLibrary />
            </aside>
            <section
              className={classNames(
                'flex-1 overflow-x-hidden',
                isTemplate && !previewMode ? 'bg-sky-50 dark:bg-neutral-900' : 'bg-neutral-50 dark:bg-neutral-900',
              )}
              ref={reactFlowWrapper}
            >
              <ErrorBoundary>
                <div className="w-[200%] flex canvas h-full">
                  <div className="relative w-full h-full">
                    {isTemplate && !previewMode && (
                      <div className="absolute top-0 right-0 z-20 p-4">
                        <button
                          onClick={() => navigate(-1)}
                          className="w-full p-2 font-medium capitalize bg-white border shadow appearance-none text-neutral-700 border-neutral-300 dark:text-neutral-400 dark:bg-neutral-800 dark:border-neutral-900 rounded-xs hover:bg-neutral-50 dark:hover:bg-neutral-900 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-neutral-500"
                        >
                          exit template mode
                        </button>
                      </div>
                    )}

                    <ReactFlow
                      nodeTypes={nodeTypes}
                      edgeTypes={edgeTypes}
                      elements={elements}
                      onConnect={onConnect}
                      onElementsRemove={onElementsRemove}
                      onLoad={onLoad}
                      panOnScroll
                      panOnScrollMode={PanOnScrollMode.Free}
                      paneMoveable={false}
                      connectionLineType={ConnectionLineType.Bezier}
                      connectionLineStyle={{ strokeWidth: 2.5 }}
                      onPaneClick={debouncedSaveDesign}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      onEdgeUpdate={onEdgeUpdate}
                    >
                      <EditorBackground isTemplate={isTemplate} />
                      <Controls fitViewParams={fitViewParams} />
                      <div id="thumbnail-preview" className="absolute z-10 invisible bottom-3 right-3 w-96 h-96">
                        <Thumbnail isTemplate={isTemplate} />
                      </div>
                    </ReactFlow>
                  </div>
                  <div className="w-full h-full">
                    {!previewMode ? null : <FormPreview elements={elements} title={title} desc={desc} />}
                  </div>
                </div>
              </ErrorBoundary>
            </section>
            <aside
              id="node-details"
              ref={sidebar}
              role="presentation"
              onMouseDown={e => isResizing && e.preventDefault()}
              className="z-10 flex flex-row flex-shrink-0 bg-white dark:bg-neutral-900 border-l border-l-neutral-200 dark:border-l-neutral-800 min-w-[256px] max-w-[414px]"
            >
              <div
                className="absolute top-0 bottom-0 z-10 w-[6px] cursor-col-resize resize-x"
                onMouseDown={startResizing}
                role="presentation"
              ></div>
              <section className="flex flex-col flex-1">
                <NodeDetails
                  flowWrapper={reactFlowWrapper}
                  title={title}
                  setTitle={setTitle}
                  desc={desc}
                  setDesc={setDesc}
                  isTemplate={isTemplate}
                />
              </section>
            </aside>
          </div>
        </div>
      </ElementsContext.Provider>
    </FlowContext.Provider>
  )
}

export const Editor: FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  const { data, loading, error } = useGetFormDesignQuery({ variables: { id: id || '' }, skip: !id })
  const design = data?.getFormDesign
  if (loading) return <div>Loading...</div>
  return (
    <ReactFlowProvider>
      <EditorBase design={design} />
    </ReactFlowProvider>
  )
}
