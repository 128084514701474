import {
  faAt,
  faCalendar,
  faCaretSquareDown,
  faCheckSquare,
  faHeartbeat,
  faInputNumeric,
  faInputText,
  faLayerGroup,
  faListAlt,
  faParagraph,
  faPhone,
  faPrescriptionBottle,
  faSignature,
  faText,
  faThumbsDown,
  faThumbsUp,
  faToggleOn,
} from '@fortawesome/pro-light-svg-icons'
import { FlowElementType } from 'src/types'

import { ComponentNode } from './types'

export const endComponents: ComponentNode[] = [
  { label: 'Approved', type: FlowElementType.Approved, icon: faThumbsUp, id: 'approved' },
  { label: 'Declined', type: FlowElementType.Declined, icon: faThumbsDown, id: 'declined' },
  { label: 'Signature', type: FlowElementType.Signature, icon: faSignature, id: 'signature' },
]

export const healthComponents: ComponentNode[] = [
  {
    label: 'Prescriptions',
    type: FlowElementType.PrescriptionsQn,
    icon: faPrescriptionBottle,
    id: 'prescriptions',
  },
  {
    label: 'Conditions',
    type: FlowElementType.ConditionsQn,
    icon: faHeartbeat,
    id: 'conditions',
  },
]

export const multiComponents: ComponentNode[] = [
  {
    label: 'Yes/No',
    type: FlowElementType.YesNoQn,
    icon: faToggleOn,
    id: 'yes-no',
  },
  {
    label: 'Dropdown',
    type: FlowElementType.DropdownQn,
    icon: faCaretSquareDown,
    id: 'dropdown',
  },
  {
    label: 'Multi select',
    type: FlowElementType.MultiSelectQn,
    icon: faCheckSquare,
    id: 'multi-select',
  },
  {
    label: 'Checklist',
    type: FlowElementType.ChecklistQn,
    icon: faListAlt,
    id: 'checklist',
  },
]

export const staticComponents: ComponentNode[] = [
  {
    label: 'Section Title',
    type: FlowElementType.SectionTitle,
    icon: faText,
    id: 'static-title',
  },
  {
    label: 'Section Body',
    type: FlowElementType.SectionBody,
    icon: faParagraph,
    id: 'static-text',
  },
]

export const dataComponents: ComponentNode[] = [
  {
    label: 'Text input',
    type: FlowElementType.TextInputQn,
    icon: faInputText,
    id: 'text-input',
  },
  {
    label: 'Email input',
    type: FlowElementType.EmailInputQn,
    icon: faAt,
    id: 'email-input',
  },
  {
    label: 'Phone input',
    type: FlowElementType.PhoneInputQn,
    icon: faPhone,
    id: 'phone-input',
  },
  {
    label: 'Number input',
    type: FlowElementType.NumberInputQn,
    icon: faInputNumeric,
    id: 'number-input',
  },
  {
    label: 'Date input',
    type: FlowElementType.DateInputQn,
    icon: faCalendar,
    id: 'date-input',
  },
]

export const groupComponents: ComponentNode[] = [
  {
    label: 'Group',
    type: FlowElementType.GroupQns,
    icon: faLayerGroup,
    id: 'group',
  },
]
