import React, { FC } from 'react'
import { ExpandableTextarea } from 'src/components/expandable-textarea'

interface ApplicationDetailsProps {
  title: string
  setTitle: (title: string) => void
  desc: string
  setDesc: (desc: string) => void
}

export const ApplicationDetails: FC<ApplicationDetailsProps> = ({ title, setTitle, desc, setDesc }) => {
  return (
    <div className="px-3 py-3 pb-8">
      <h5 className="mb-3 tracking-wide capitalize text-neutral-900 dark:text-neutral-400">application details</h5>
      <div className="mt-2 space-y-4">
        <ExpandableTextarea
          name="title"
          value={title}
          label="Title"
          required
          onChange={event => setTitle(event.target.value)}
          placeholder="Title"
          className="text-input"
          autoComplete="off"
          spellCheck={false}
        />
        <ExpandableTextarea
          name="desc"
          value={desc}
          label="Description"
          onChange={event => setDesc(event.target.value)}
          placeholder="Description"
          className="text-input"
          autoComplete="off"
          spellCheck={false}
        />
      </div>
    </div>
  )
}
