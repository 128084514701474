import { RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import { Logo } from 'src/components/logo'

import { Dashboard } from './home/dashboard'

export const Home: FC<RouteComponentProps> = () => {
  return (
    <div className="flex flex-col h-screen">
      <header className="h-[60px] flex-none text-sm leading-6 bg-white dark:bg-neutral-900 border-b border-neutral-200 dark:border-neutral-800">
        <div className="flex items-center justify-between px-4 py-3">
          <div className="flex-1">
            <button className="appearance-none">
              <Logo className="h-8" />
            </button>
          </div>
          <nav aria-label="Global" className="px-4"></nav>
        </div>
      </header>
      <Dashboard />
    </div>
  )
}
