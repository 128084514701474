import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCompassDrafting, faFilePdf, faTable } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import DOMPurify from 'dompurify'
import { navigate } from 'gatsby'
import React, { FC, useState } from 'react'
import { createFormDesign } from 'src/backend/create-form-design'
import { getStartNode } from 'src/common/utils'
import { DesignType, useGetFormDesignsQuery } from 'src/graphql-schema'

export const Dashboard: FC = () => {
  const [isCreating, setIsCreating] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { data } = useGetFormDesignsQuery({ variables: { data: {} } })
  const designs = data?.getFormDesigns || []
  return (
    <div className="container px-4 mx-auto mt-8">
      <div className="grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] gap-x-8 gap-y-4 mb-4">
        <button
          disabled={isCreating}
          className="flex items-center px-3 py-5 bg-white border rounded appearance-none hover:bg-neutral-100 border-neutral-300 dark:border-neutral-600 dark:bg-neutral-800 dark:hover:bg-neutral-900"
          onClick={async () => {
            setIsCreating(true)
            setHasError(false)
            try {
              const data = await createFormDesign({
                data: { type: DesignType.Form, config: { elements: [getStartNode()] } },
              })
              const designId = data?.id
              if (designId) {
                navigate(`/designs/${designId}/editor`)
              }
              // TODO: handle empty design id
            } catch (error) {
              setHasError(true)
            } finally {
              setIsCreating(false)
            }
          }}
        >
          <div className="flex items-center justify-center w-8 h-8 mr-3">
            <FontAwesomeIcon icon={faCompassDrafting} className="w-6 h-6 text-sky-500 dark:text-sky-600" />
          </div>
          <div className="flex-grow text-left">
            <div className="font-semibold">New design file</div>
            <div className="text-[12px] text-neutral-500 dark:text-neutral-400">Design and preview</div>
          </div>
          <div className="flex items-center justify-center w-8 h-8">
            <FontAwesomeIcon icon={faPlus} className="w-3 h-3" />
          </div>
        </button>
        <button className="flex items-center px-3 py-5 bg-white border rounded appearance-none hover:bg-neutral-100 border-neutral-300 dark:border-neutral-600 dark:bg-neutral-800 dark:hover:bg-neutral-900">
          <div className="flex items-center justify-center w-8 h-8 mr-3">
            <FontAwesomeIcon icon={faTable} className="w-6 h-6 text-sky-500 dark:text-sky-600" />
          </div>
          <div className="flex-grow text-left">
            <div className="font-semibold">New rates file</div>
            <div className="text-[12px] text-neutral-500 dark:text-neutral-400">Add premium rates</div>
          </div>
          <div className="flex items-center justify-center w-8 h-8">
            <FontAwesomeIcon icon={faPlus} className="w-3 h-3" />
          </div>
        </button>
        <button className="flex items-center px-3 py-5 bg-white border rounded appearance-none hover:bg-neutral-100 border-neutral-300 dark:border-neutral-600 dark:bg-neutral-800 dark:hover:bg-neutral-900">
          <div className="flex items-center justify-center w-8 h-8 mr-3">
            <FontAwesomeIcon icon={faFilePdf} className="w-6 h-6 text-sky-500 dark:text-sky-600" />
          </div>
          <div className="flex-grow text-left">
            <div className="font-semibold">New pdf design</div>
            <div className="text-[12px] text-neutral-500 dark:text-neutral-400">Add pdf pack</div>
          </div>
          <div className="flex items-center justify-center w-8 h-8">
            <FontAwesomeIcon icon={faPlus} className="w-3 h-3" />
          </div>
        </button>
      </div>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] gap-8 mt-16">
        {designs.map(design => (
          <button
            key={design.id}
            className="relative appearance-none group"
            onClick={() => navigate(`/designs/${design.id}/editor`)}
          >
            <div className="w-full overflow-hidden bg-gray-200 rounded-md aspect-w-1 aspect-h-1 group-hover:opacity-75 bg-gradient-to-br from-neutral-200 to-neutral-300 dark:from-neutral-800 dark:to-neutral-900">
              {design.config.thumbnail ? (
                <div
                  className="flex items-center justify-center p-2 thumbnail-holder"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(design.config.thumbnail) }}
                />
              ) : null}
            </div>
            {design.type === DesignType.Template ? (
              <div className="absolute right-2 top-2">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 dark:bg-purple-800 text-purple-800 dark:text-purple-100">
                  Template
                </span>
              </div>
            ) : (
              <div className="absolute right-2 top-2">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-sky-100 dark:bg-sky-800 text-sky-800 dark:text-sky-100">
                  Design
                </span>
              </div>
            )}
            <div className="flex justify-between mt-3">
              <div className="flex items-center">
                <div className="mr-4">
                  <FontAwesomeIcon icon={faCompassDrafting} className="w-6 h-6 text-sky-500 dark:text-sky-600" />
                </div>
                <div className="text-left">
                  <h3 className="text-sm text-neutral-700 dark:text-white">{design.name}</h3>
                  <p className="mt-1 text-[12px] text-gray-500 dark:text-neutral-400">{`updated ${formatDistanceToNow(
                    new Date(design.updatedAt),
                  )} ago.`}</p>
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}
