import { faFileImport, faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import compact from 'lodash/compact'
import React, { ChangeEventHandler, FC } from 'react'
import { DragDropFile } from 'src/components/drag-drop-file'
import { ExclusionsListItem, ExclusionsMeta } from 'src/types'
import XLSX from 'xlsx'

import { BaseDetails } from './base-qn-details'

const dataToExclusionList = (data: [string, string][]): ExclusionsListItem[] => {
  return compact(
    data.map(row => {
      const [items = '', outcome = ''] = row || []
      const normalizedOutcome = outcome.trim().toLowerCase()
      if (!items.trim()) return undefined
      return {
        items: compact(items.split(',').map(item => item.trim().toLowerCase())),
        outcome: normalizedOutcome === 'A' ? 'approved' : outcome === 'R' ? 'review' : 'declined',
      }
    }),
  )
}

interface PrescriptionsDetailsProps {
  data: ExclusionsMeta
  updateElement: (data: ExclusionsMeta) => void
}
export const PrescriptionDetails: FC<PrescriptionsDetailsProps> = ({ data, updateElement }) => {
  const exclusionsList = data.exclusionsList || []

  const updateNode = (list: ExclusionsListItem[], fileName: string) => {
    if (!data) return
    updateElement({
      ...data,
      exclusionsList: list,
      listSource: fileName,
    })
  }
  const handleFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = event => {
      if (!event.target) return
      const ab = event.target.result
      const wb = XLSX.read(ab, { type: 'array' })
      const worksheetName = wb.SheetNames[0]
      const ws = wb.Sheets[worksheetName]
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 }) as [string, string][]
      const list = dataToExclusionList(data)
      updateNode(list, file.name)
    }
    reader.readAsArrayBuffer(file)
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const files = event.target.files
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <BaseDetails data={data} updateElement={updateElement}>
      <div className="my-4">
        <label htmlFor="exclusions list" className="label">
          Exclusions list
        </label>
        {exclusionsList.length === 0 ? (
          <DragDropFile handleFile={handleFile}>
            <div className="flex justify-center px-6 pt-5 pb-6 border border-dashed rounded-md border-neutral-300 dark:border-neutral-700">
              <div className="space-y-1 text-center">
                <FontAwesomeIcon
                  className="w-12 h-12 text-neutral-400 dark:text-neutral-600"
                  icon={faFileSpreadsheet}
                />

                <div className="flex flex-col text-neutral-600 dark:text-neutral-400">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer text-sky-600 hover:text-sky-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-offset-1 focus-within:ring-sky-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      onChange={handleChange}
                      className="sr-only"
                      accept="xlsx,xlsm,xls"
                    />
                  </label>
                  <p className="pt-1">or drag and drop</p>
                </div>
                <p className="text-neutral-500">XLSX, XLSM, XLS up to 10MB</p>
              </div>
            </div>
          </DragDropFile>
        ) : (
          <div className="my-4">
            <p
              id="list-message"
              className="text-neutral-900 dark:text-neutral-300"
            >{`Loaded ${exclusionsList.length} records from ${data.listSource}`}</p>
            <div className="mt-2 text-neutral-600 dark:text-neutral-400">
              <label
                htmlFor="file-upload-another"
                className="relative flex items-center space-x-2 cursor-pointer text-sky-600 hover:text-sky-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-offset-1 focus-within:ring-sky-500"
              >
                <FontAwesomeIcon icon={faFileImport} className="h-4" />
                <span>Replace records</span>
                <input
                  id="file-upload-another"
                  name="file-upload"
                  type="file"
                  onChange={handleChange}
                  className="sr-only"
                  accept="xlsx,xlsm,xls"
                />
              </label>
            </div>
          </div>
        )}
      </div>
    </BaseDetails>
  )
}
