import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'

import { ComponentNode, LibraryItemProps } from './types'
import { classNames } from './utils'

interface ComponentSectionProps extends LibraryItemProps {
  nodes: ComponentNode[]
  title: string
}
export const ComponentsSection: FC<ComponentSectionProps> = ({ onDragStart, nodes = [], title }) => {
  if (nodes.length === 0) return null
  return (
    <div>
      <h6 className="mb-2 capitalize text-neutral-900 dark:text-neutral-400">{title}</h6>
      <div className="space-y-4">
        {nodes.map(({ label, icon, type, id }) => (
          <div key={id} className={classNames} onDragStart={event => onDragStart(event, { type })} draggable>
            <FontAwesomeIcon icon={icon} className="w-4 h-4 text-neutral-900 dark:text-neutral-100" />
            <span className="capitalize text-neutral-900 dark:text-neutral-100">{label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
