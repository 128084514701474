import React, { FC } from 'react'

interface EditorBackgroundProps {
  isTemplate?: boolean
}

export const EditorBackground: FC<EditorBackgroundProps> = ({ isTemplate }) => {
  return (
    <svg className="absolute top-0 left-0 w-full h-full bg-neutral-50 dark:bg-neutral-700 fill-neutral-500 stroke-neutral-200 dark:fill-neutral-600 dark:stroke-neutral-600">
      <pattern id="bg-dots" x="14.875" y="12.03125" width="16" height="16" patternUnits="userSpaceOnUse">
        <circle cx="0.4" cy="0.4" r="0.4"></circle>
      </pattern>
      <pattern id="bg-lines" x="13" y="14" width="16" height="16" patternUnits="userSpaceOnUse">
        <path strokeWidth="0.4" d="M8 0 V16 M0 8 H16"></path>
      </pattern>
      <rect x="0" y="0" width="100%" height="100%" fill={`url(#${isTemplate ? 'bg-lines' : 'bg-dots'})`}></rect>
    </svg>
  )
}
