import React, { FC } from 'react'
import { classNames } from 'src/common/utils'
import { useBubbleEntry } from 'src/hooks/use-bubble-entry'

interface SectionTitleHeaderProps {
  key: string
  id: string
  text?: string
  sticky?: boolean
}

export const SectionTitle: FC<SectionTitleHeaderProps> = ({ id, text = '', sticky = false }) => {
  const qnId = `qn-${id}`
  useBubbleEntry(qnId)
  return (
    <section
      id={qnId}
      className={classNames(
        'bg-white dark:bg-neutral-800 dark:text-neutral-200',
        sticky ? 'sticky top-0 z-10' : '',
        text ? 'p-4' : 'my-4 border-t border-t-neutral-200 dark:border-t-neutral-700',
        text ? '' : 'first:hidden',
      )}
    >
      <h3 className="m-0 text-neutral-900 dark:text-neutral-200">{text || ''}</h3>
    </section>
  )
}
