import React, { FC } from 'react'
import { BaseMeta, FlowElementType } from 'src/types'

import { BaseDetails } from './base-qn-details'
import { DropdownDetails } from './dropdown-qn-details'
import { PrescriptionDetails } from './prescriptions-qn-details'

interface ElementDetailsProps {
  data: BaseMeta
  updateElement: (data: BaseMeta) => void
}
export const ElementDetails: FC<ElementDetailsProps> = ({ data, updateElement }) => {
  if (!data) return null
  const type = data.type as FlowElementType

  switch (type) {
    case FlowElementType.DropdownQn:
    case FlowElementType.MultiSelectQn:
      return <DropdownDetails data={data} updateElement={updateElement} />
    case FlowElementType.SectionTitle:
    case FlowElementType.SectionBody:
    case FlowElementType.YesNoQn:
    case FlowElementType.TextInputQn:
    case FlowElementType.EmailInputQn:
    case FlowElementType.PhoneInputQn:
    case FlowElementType.DateInputQn:
    case FlowElementType.NumberInputQn:
    case FlowElementType.Declined:
    case FlowElementType.Approved:
    case FlowElementType.Signature:
      return <BaseDetails key={data.id} data={data} updateElement={updateElement} />
    case FlowElementType.PrescriptionsQn:
      return <PrescriptionDetails data={data} updateElement={updateElement} />
    default:
      return null
  }
}
