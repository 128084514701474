import React, { FC, useCallback, useState } from 'react'
import { nodeLabels, nodePlaceholders, nodeTitles } from 'src/common/utils'
import { ExpandableTextarea } from 'src/components/expandable-textarea'
import { BaseMeta, FlowElementType } from 'src/types'

interface YesNoQnDetailsProps {
  data: BaseMeta
  updateElement: (data: BaseMeta) => void
}

export const BaseDetails: FC<YesNoQnDetailsProps> = ({ data, updateElement, children }) => {
  const [question, setQuestion] = useState(data.question || '')
  const type = data.type as FlowElementType

  const label = nodeLabels[type] || 'Question'
  const placeholder = nodePlaceholders[type] || 'Question text'
  const updateQuestion = useCallback(
    (value: string) => {
      if (!data) return
      updateElement({
        ...data,
        question: value,
      })
    },
    [data, updateElement],
  )

  return (
    <div className="px-3 py-4">
      <h5 className="mb-3 tracking-wide capitalize text-neutral-700 dark:text-neutral-400">
        <span className="leading-none">{nodeTitles[type] || ''}</span>
      </h5>

      <div className="mt-4">
        <ExpandableTextarea
          key={data.id}
          label={label}
          name="question"
          value={question}
          onChange={event => {
            const value = event.target.value
            setQuestion(value)
            updateQuestion(value)
          }}
          placeholder={placeholder}
          className="block w-full shadow-sm resize-none dark:text-neutral-300 border-neutral-300 dark:border-neutral-700 dark:bg-neutral-800 rounded-xs focus:ring-sky-500 focus:border-sky-500"
          autoComplete="off"
          spellCheck={false}
        />
      </div>
      {children}
    </div>
  )
}
