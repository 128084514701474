import { faArrowLeft, faArrowRotateRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMachine } from '@xstate/react'
import { useFormikContext } from 'formik'
import { gsap } from 'gsap'
import isArray from 'lodash/isArray'
import React, { FC, useCallback, useEffect } from 'react'
import { dateFormat, inspectStateCharts } from 'src/common/config'
import { classNames, getMetaKeyFromState } from 'src/common/utils'
import { DropdownMeta, ExclusionsMeta, FlowElementType, MultiSelectMeta, NodeMeta, TContext, TEvent } from 'src/types'
import { v4 } from 'uuid'
import { ActionObject, StateMachine } from 'xstate'

import { ApprovedControls } from './form-wizard/approved-control'
import { DropdownControls } from './form-wizard/dropdown-control'
import { MultiSelectControls } from './form-wizard/multi-select-control'
import { PrescriptionsControls } from './form-wizard/prescriptions-control'
import { QuestionBubble } from './form-wizard/question-bubble'
import { SectionTitle } from './form-wizard/section-title'
import { SignatureBubble } from './form-wizard/signature-bubble'
import { SignatureControls } from './form-wizard/signature-control'
import { TextInputControls } from './form-wizard/text-input-control'
import { YesNoControls } from './form-wizard/yes-no-control'

const questionBubbleTypes = [
  FlowElementType.YesNoQn,
  FlowElementType.DropdownQn,
  FlowElementType.TextInputQn,
  FlowElementType.EmailInputQn,
  FlowElementType.PhoneInputQn,
  FlowElementType.DateInputQn,
  FlowElementType.NumberInputQn,
  FlowElementType.SectionBody,
  FlowElementType.MultiSelectQn,
  FlowElementType.PrescriptionsQn,
] as string[]

interface QuestionBubble {
  id: string
  type: string
  question: string
  sectionTitle?: string
  sticky?: boolean
}
interface FormWizardProps {
  key: string
  title: string
  desc: string
  machine: StateMachine<
    TContext,
    any,
    TEvent,
    {
      value: any
      context: TContext
    },
    ActionObject<TContext, TEvent>
  >
}

export const FormWizard: FC<FormWizardProps> = ({ machine, title, desc }) => {
  const { resetForm, values, touched } = useFormikContext<Record<string, string>>()
  const [questions, setQuestions] = React.useState<QuestionBubble[]>([])
  const [state, send] = useMachine(machine, { devTools: inspectStateCharts })

  const { key, parentKey } = getMetaKeyFromState(state)
  const meta: NodeMeta = state.meta[key] || {}
  const parentMeta = state.meta[parentKey] || {}
  const question = meta.question || ''
  const type = meta.type
  const sectionTitle = parentMeta.type === FlowElementType.TemplateQns && parentMeta.title

  const onAnswered = useCallback(
    (answer: string | string[]) => {
      const isMultiple = isArray(answer)
      send({ type: 'NEXT', key, [isMultiple ? 'values' : 'value']: answer })
      requestAnimationFrame(() => {
        gsap.to('#chat', { duration: 0.4, scrollTo: { y: 'max' } })
      })
    },
    [key, send],
  )

  useEffect(() => {
    setQuestions(currentQuestions => {
      const alreadyAdded = currentQuestions.find(question => question.id === key)
      if (alreadyAdded) return currentQuestions
      const previousSectionTitle = currentQuestions[currentQuestions.length - 1]?.sectionTitle
      if (previousSectionTitle !== sectionTitle) {
        return [
          ...currentQuestions,
          {
            id: parentKey || v4(),
            type: FlowElementType.SectionTitle,
            question: sectionTitle,
            sectionTitle,
            sticky: true,
          },
          { id: key, type, question, sectionTitle },
        ]
      }
      return [...currentQuestions, { id: key, type, question, sectionTitle }]
    })
  }, [question, key, parentKey, type, sectionTitle])

  useEffect(() => {
    requestAnimationFrame(() => {
      const tl = gsap.to('#chat', { duration: 0.4, scrollTo: { y: 'max' } })
      return () => {
        tl.kill()
      }
    })
  }, [state.value])

  return (
    <div className="flex flex-col w-full h-full bg-neutral-50 dark:bg-neutral-900">
      <div className="flex flex-row items-center justify-between flex-shrink-0 mx-auto mt-16 mb-4 w-96">
        <button
          onClick={() => {
            if (questions.length < 2) return
            const newQuestions = questions.slice(0, -1)
            const lastQn = newQuestions.length && newQuestions[newQuestions.length - 1]
            if (lastQn) {
              setQuestions(newQuestions)
              resetForm({ values: { ...values, [lastQn.id]: '' }, touched: { ...touched, [lastQn.id]: false } })
              send('PREV')
            }
          }}
          className={classNames('flex items-center space-x-2 appearance-none', questions.length < 2 ? 'invisible' : '')}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" aria-hidden="true" />
          <span>Previous Qn</span>
        </button>
        <button
          onClick={() => {
            setQuestions([])
            resetForm()
            send('RESET')
          }}
          className="flex items-center space-x-2 appearance-none "
        >
          <FontAwesomeIcon icon={faArrowRotateRight} className="w-4 h-4" aria-hidden="true" />
          <span>Restart</span>
        </button>
      </div>
      <div className="flex flex-col mx-auto mb-8 bg-white dark:bg-neutral-800 shadow w-96 h-[42rem]">
        <div className="flex-shrink-0 p-4 border-b border-b-neutral-200 dark:border-b-neutral-700">
          <h3 className="text-lg text-center uppercase">{title}</h3>
          <p className="mt-3 font-light text-center uppercase text-neutral-600">{desc}</p>
        </div>
        <div id="chat" className="flex-1 overflow-y-scroll prose-sm prose prose-slate">
          {questions.map(question => {
            if (questionBubbleTypes.includes(question.type)) {
              return <QuestionBubble key={question.id} id={question.id} question={question.question} />
            } else if (question.type === FlowElementType.SectionTitle) {
              return (
                <SectionTitle key={question.id} id={question.id} text={question.question} sticky={question.sticky} />
              )
            } else if (question.type === FlowElementType.Declined) {
              return (
                <div key={question.id} className="p-4 text-rose-600 dark:text-neutral-200 bg-rose-50 dark:bg-rose-800">
                  {question.question}
                </div>
              )
            } else if (question.type === FlowElementType.Approved) {
              return (
                <div
                  key={question.id}
                  className="p-4 text-green-700 dark:text-neutral-200 bg-green-50 dark:bg-green-800"
                >
                  {question.question}
                </div>
              )
            } else if (question.type === FlowElementType.Signature) {
              return <SignatureBubble key={question.id} id={question.id} question={question.question} />
            }
          })}
        </div>
        <div className="flex-shrink-0 p-4 border-t border-t-neutral-200 dark:border-t-neutral-700 empty:hidden">
          {type === FlowElementType.YesNoQn ? <YesNoControls id={key} onAnswered={onAnswered} /> : null}
          {type === FlowElementType.DropdownQn ? (
            <DropdownControls id={key} options={(meta as DropdownMeta).options || []} onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.TextInputQn ? (
            <TextInputControls id={key} type="text" placeholder="" onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.EmailInputQn ? (
            <TextInputControls id={key} type="email" placeholder="you@example.com" onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.PhoneInputQn ? (
            <TextInputControls id={key} type="tel" placeholder="+123456789" onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.DateInputQn ? (
            <TextInputControls id={key} type="text" placeholder={dateFormat.toLowerCase()} onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.NumberInputQn ? (
            <TextInputControls id={key} type="number" placeholder="" onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.MultiSelectQn ? (
            <MultiSelectControls id={key} options={(meta as MultiSelectMeta).options || []} onAnswered={onAnswered} />
          ) : null}
          {type === FlowElementType.PrescriptionsQn ? (
            <PrescriptionsControls
              id={key}
              onAnswered={onAnswered}
              placeholder="comma separated names"
              exclusionsList={(meta as ExclusionsMeta).exclusionsList || []}
            />
          ) : null}
          {type === FlowElementType.Signature ? <SignatureControls id={key} onAnswered={onAnswered} /> : null}
          {type === FlowElementType.Approved ? <ApprovedControls /> : null}
        </div>
      </div>
    </div>
  )
}
