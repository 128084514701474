import debug from 'debug'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import map from 'lodash/map'
import pick from 'lodash/pick'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'
import uniqBy from 'lodash/uniqBy'
import { createContext, Dispatch, SetStateAction } from 'react'
import { addEdge, Edge, isEdge, isNode, Node, OnLoadParams } from 'react-flow-renderer/nocss'
import { debugNamespaces } from 'src/common/config'
import {
  BaseNodeMeta,
  ConditionType,
  DropdownMeta,
  EdgeMeta,
  EqualToCondition,
  ExclusionsMeta,
  FlowElements,
  FlowElementType,
  GroupMeta,
  isGroupData,
  MachineState,
  NodeMeta,
} from 'src/types'
import { v4 } from 'uuid'

export const machineDebug = debug('machine')
debug.disable()
debugNamespaces.forEach(namespace => debug.enable(namespace))

export const isBrowser = () => typeof window !== 'undefined'

export const FlowContext = createContext<OnLoadParams<FlowElements> | null>(null)

export const ElementsContext = createContext<{
  elements: FlowElements
  setElements: Dispatch<SetStateAction<FlowElements>>
}>({
  elements: [],
  setElements: () => undefined,
})

export function classNames(...classes: (string | undefined | null)[]) {
  return classes.filter(Boolean).join(' ')
}

let nodeId = 1
export const getNodeId = () => nodeId++
export const setNodeId = (id: number) => (nodeId = id)
export const getCurrentNodeId = () => nodeId

const configKeys = ['id', 'type', 'data', 'source', 'sourceHandle', 'target', 'targetHandle'] as const
export const compareElements = (nextElements: FlowElements, prevElements?: FlowElements) => {
  if (!prevElements) return false
  const nextElementsConfig = nextElements.map(element => pick(element, configKeys))
  const prevElementsConfig = prevElements.map(element => pick(element, configKeys))
  return isEqual(nextElementsConfig, prevElementsConfig)
}

const getBaseEdge = (edgeParams: Edge<EdgeMeta>) => ({
  ...edgeParams,
  id: v4(),
  type: 'SelectableEdge',
})

const getYesNoEdge = (sourceNodeEdges: Edge<EdgeMeta>[], edgeParams: Edge<EdgeMeta>): Edge<EdgeMeta> | undefined => {
  const currentEdge = sourceNodeEdges[0]
  const edgeConfig = getBaseEdge(edgeParams)
  if (!currentEdge) {
    edgeConfig.data = {
      condition: {
        type: 'strict',
        conditions: [{ id: v4(), type: ConditionType.EqualTo, value: 'yes', source: 'event' }],
      },
    }
  } else {
    const condition = currentEdge.data?.condition
    const equalToCondition = condition?.conditions?.find(
      cond => cond.type === ConditionType.EqualTo,
    ) as EqualToCondition
    if (equalToCondition) {
      edgeConfig.data = {
        condition: {
          type: 'strict',
          conditions: [
            {
              id: v4(),
              type: ConditionType.EqualTo,
              value: equalToCondition.value === 'yes' ? 'no' : 'yes',
              source: 'event',
            },
          ],
        },
      }
    }
  }
  return edgeConfig
}

const getEdge = (currentElements: FlowElements, edgeParams: Edge<EdgeMeta>): Edge<EdgeMeta> | undefined => {
  const sourceNode = currentElements.find(element => element.id === edgeParams.source)
  const sourceNodeEdges = currentElements.filter(element => {
    if (!isEdge(element)) return false
    return sourceNode?.id === element.source
  }) as Edge<EdgeMeta>[]
  if (sourceNode?.type === FlowElementType.YesNoQn) return getYesNoEdge(sourceNodeEdges, edgeParams)
  if (edgeParams.source === FlowElementType.Initial && sourceNodeEdges.length > 0) return
  return getBaseEdge(edgeParams)
}

export const addNewEdge = (currentElements: FlowElements, edgeParams: Edge<EdgeMeta>) => {
  const edge = getEdge(currentElements, edgeParams)
  if (!edge) return currentElements
  return addEdge(edge, currentElements)
}

export const getStartNode = () => ({
  id: FlowElementType.Initial,
  type: FlowElementType.Initial,
  position: { x: 0, y: 0 },
  selectable: false,
})

export const getMetaKeyFromState = (state: MachineState) => {
  const stateName = last(sortBy(state.toStrings(), name => name.length))
  const stateKeys = split(stateName, '.')
  if (stateKeys.length === 0) return { parentKey: '', key: '' }
  if (stateKeys.length === 1) return { parentKey: '', key: stateKeys[0] }
  if (stateKeys.length > 1) {
    const [parentKey, key] = stateKeys.slice(-2)
    return { parentKey, key }
  }
  return { parentKey: '', key: '' }
}

export const getAncestors = (elements: FlowElements, element: Node<NodeMeta>): Node<NodeMeta>[] => {
  const elementEdges = elements.filter(e => {
    if (!isEdge(e)) return false
    return e.target === element.id
  }) as Edge<any>[]

  const parentElements = compact(
    map(elementEdges, elementEdge => elements.find(e => e.id === elementEdge?.source)),
  ).filter(e => e.id !== FlowElementType.Initial) as Node<NodeMeta>[]

  const ancestors = flatten(map(parentElements, parentElement => getAncestors(elements, parentElement)))
  return uniqBy([...ancestors, ...parentElements], 'id').filter(e => e.type !== FlowElementType.TemplateQns)
}

export const findNodeById = (elements: FlowElements, id: string): Node<NodeMeta> | undefined => {
  let foundNode: Node<NodeMeta> | undefined
  for (const element of elements) {
    if (isNode(element)) {
      if (element.data && isGroupData(element.data)) {
        const questionNode = find(element.data?.questions, question => {
          return question.id === id
        })
        if (questionNode) return questionNode as Node<NodeMeta>
      }
      if (element.id === id) return element
    }
  }
  return foundNode
}

export const nodesToOptions = (nodes: Node<NodeMeta>[]) => {
  return compact(
    flatten(
      map(nodes, node => {
        if (!node) return
        if (node.data && isGroupData(node.data)) {
          return map(node.data?.questions, question => {
            return {
              id: question.id,
              name: question.question || question.type || '',
            }
          })
        }
        return { id: node.id, name: node.data?.question || node.type || '' }
      }),
    ),
  )
}

export const duplicateElements = (elements: FlowElements): FlowElements => {
  const nodes = elements.filter(e => isNode(e)) as Node[]
  const edges = elements.filter(e => isEdge(e)) as Edge<EdgeMeta>[]

  const idMap = nodes.reduce<Record<string, string>>((acc, node) => {
    acc[node.id] = node.id === FlowElementType.Initial ? FlowElementType.Initial : v4()
    return acc
  }, {})

  const newNodes = nodes.map(node => ({ ...node, id: idMap[node.id] }))
  const newEdges = edges.map(edge => {
    const newEdge = cloneDeep(edge)
    newEdge.source = idMap[newEdge.source]
    newEdge.target = idMap[newEdge.target]

    const edgeConditions = newEdge.data?.condition?.conditions
    if (edgeConditions) {
      const newConditions = edgeConditions.map(cond => ({
        ...cond,
        source: cond.source === 'event' ? 'event' : idMap[cond.source],
      }))
      set(newEdge, 'data.condition.conditions', newConditions)
    }
    return newEdge
  })
  return [...newNodes, ...newEdges]
}

export const nodeData: { [key in keyof typeof FlowElementType]?: NodeMeta } = {
  YesNoQn: { options: ['yes', 'no'] } as DropdownMeta,
  PrescriptionsQn: { options: ['approved', 'declined', 'review'] } as ExclusionsMeta,
  ConditionsQn: { options: ['approved', 'declined', 'review'] } as ExclusionsMeta,
  GroupQns: { questions: [] as BaseNodeMeta[] } as GroupMeta,
  Signature: {
    question:
      'I hereby declare that the information provided is true and correct. I also understand that any willful dishonesty may render for refusal of this application.',
  } as NodeMeta,
}

export const nodeTitles: { [key in keyof typeof FlowElementType]: string } = {
  YesNoQn: 'Yes/No Question',
  PrescriptionsQn: 'Prescriptions',
  ConditionsQn: 'Conditions',
  GroupQns: 'Group',
  Signature: 'Signature',
  NumberInputQn: 'Number type question',
  TextInputQn: 'Text question',
  PhoneInputQn: 'Phone number question',
  EmailInputQn: 'Email question',
  Approved: 'Approved',
  Declined: 'Declined',
  SectionBody: 'Section Body',
  SectionTitle: 'Section Title',
  DateInputQn: 'Date type question',
  DropdownQn: 'Dropdown question',
  MultiSelectQn: 'Multi select question',
  ChecklistQn: 'Checklist question',
  Initial: '',
  SelectableEdge: '',
  TemplateQns: 'Template',
}

export const nodeLabels: { [key in keyof typeof FlowElementType]?: string } = {
  Declined: 'Declined message',
  Approved: 'Approved message',
  Signature: 'Signature',
}

export const nodePlaceholders: { [key in keyof typeof FlowElementType]?: string } = {
  Declined: 'add message',
  Approved: 'add message',
  SectionBody: 'section subtitle',
  SectionTitle: 'section title',
  Signature: 'declaration text',
}

export const getNodeBorderClasses = (selected?: boolean) =>
  classNames(
    'border rounded-sm w-80',
    selected
      ? 'shadow-lg shadow-sky-600/20 border-sky-600 ring-1 ring-sky-600 bg-sky-50 dark:bg-neutral-900'
      : 'bg-white dark:bg-neutral-800 shadow border-neutral-200 dark:border-neutral-900',
  )
