import isEmpty from 'lodash/isEmpty'
import React, { FC, useCallback, useState } from 'react'
import { ExpandableTextarea } from 'src/components/expandable-textarea'
import { BaseMeta, GroupMeta } from 'src/types'

import { ElementDetails } from './element-details'

interface GroupDetailsProps {
  data: GroupMeta
  updateElement: (data: GroupMeta, options?: { selected?: boolean }) => void
}

export const GroupDetails: FC<GroupDetailsProps> = ({ data, updateElement }) => {
  const [title, setTitle] = useState(data.title || '')
  const questionMeta = data.questions?.find(question => question.id === data.selected)

  const removeQuestion = useCallback(() => {
    const updatedQuestions = data.questions?.filter(question => question.id !== questionMeta?.id) || []
    const updatedData = {
      ...data,
      selected: isEmpty(updatedQuestions) ? undefined : updatedQuestions[0].id,
      questions: updatedQuestions,
    }
    updateElement(updatedData, { selected: true })
  }, [questionMeta, data, updateElement])

  const updateQuestion = useCallback(
    (questionData: BaseMeta) => {
      const updatedData = {
        ...data,
        questions: data.questions?.map(question => {
          if (question.id !== questionData.id) return question
          return {
            ...question,
            ...questionData,
          }
        }),
      }
      updateElement(updatedData)
    },
    [data, updateElement],
  )

  return (
    <div className="py-4 space-y-4 divide-y divide-neutral-200 dark:divide-neutral-800">
      <div className="px-3">
        <h5 className="flex items-center mb-8 space-x-2 font-medium text-neutral-900 dark:text-neutral-400">
          <span className="leading-none">Group details</span>
        </h5>
        <div className="mt-2">
          <ExpandableTextarea
            name="group-title"
            label="Group title (optional)"
            value={title}
            onChange={event => {
              const value = event.target.value
              setTitle(value)
              updateElement({
                ...(data || {}),
                title: value,
              })
            }}
            placeholder="group title"
            className="text-input"
            autoComplete="off"
            spellCheck={false}
          />
        </div>
      </div>
      {questionMeta ? <ElementDetails data={questionMeta} updateElement={updateQuestion} /> : null}
      {questionMeta ? (
        <div className="py-2">
          <button
            onClick={() => removeQuestion()}
            className="appearance-none w-full px-2.5 py-1.5 text-rose-700 dark:text-rose-500 bg-white dark:bg-neutral-900 hover:text-rose-500 dark:hover:text-rose-600 focus:outline-none"
          >
            Delete question
          </button>
        </div>
      ) : null}
    </div>
  )
}
