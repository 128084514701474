import React, { FC, RefObject, useCallback, useContext } from 'react'
import { isEdge, isNode, removeElements, useStoreActions, useStoreState } from 'react-flow-renderer/nocss'
import { ElementsContext } from 'src/common/utils'
import { useLayoutGraph } from 'src/hooks/use-layout-graph'
import { BaseMeta, FlowElementType } from 'src/types'

import { ApplicationDetails } from './node-details/application-details'
import { EdgeDetails } from './node-details/edge-details'
import { ElementDetails } from './node-details/element-details'
import { GroupDetails } from './node-details/group-details'
import { NewTemplate } from './node-details/new-template'
import { TemplateDetails } from './node-details/template-details'

interface NodeDetailsProps {
  flowWrapper: RefObject<HTMLDivElement>
  title: string
  setTitle: (title: string) => void
  desc: string
  setDesc: (desc: string) => void
  isTemplate?: boolean
}
export const NodeDetails: FC<NodeDetailsProps> = ({ flowWrapper, title, desc, setTitle, setDesc, isTemplate }) => {
  const { elements, setElements } = useContext(ElementsContext)
  const { selectedElement, noneSelected } = useStoreState(state => {
    const { selectedElements } = state
    if (!selectedElements) return { noneSelected: true, selectedElement: undefined }
    const singleSelected = selectedElements.length === 1
    const selectedElement = singleSelected ? selectedElements[0] : undefined
    return { selectedElement, noneSelected: selectedElements.length === 0 }
  })
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements)

  const layoutElements = useLayoutGraph()
  const edgeElement = selectedElement && isEdge(selectedElement) ? selectedElement : undefined
  const nodeElement = selectedElement && isNode(selectedElement) ? selectedElement : undefined

  const removeElement = useCallback(
    () =>
      setElements(els => {
        if (selectedElement) return removeElements([selectedElement], els)
        return els
      }),
    [selectedElement, setElements],
  )

  const updateElement = useCallback(
    (data: BaseMeta, options?: { selected?: boolean }) => {
      if (!nodeElement) return
      const updatedNode = {
        ...nodeElement,
        data,
      }
      setElements(currentElements => {
        return currentElements.filter(e => e.id !== nodeElement.id).concat([updatedNode])
      })
      if (options?.selected) setSelectedElements([updatedNode])
    },
    [nodeElement, setElements, setSelectedElements],
  )

  const hasSingleElement = elements.length === 1
  return (
    <div className="flex flex-col flex-1 divide-y text-neutral-600 divide-neutral-200 dark:divide-neutral-800">
      <div className="px-3 py-4">
        <button
          disabled={hasSingleElement}
          onClick={() => {
            const options: { width?: number; height?: number } = {}
            if (flowWrapper.current) {
              const flowBounds = flowWrapper.current.getBoundingClientRect()
              options.width = flowBounds.width
              options.height = flowBounds.height
            }

            layoutElements(options)
          }}
          className="btn"
        >
          prettify graph
        </button>
      </div>
      <div className="hidden py-4">{nodeElement?.id}</div>
      {noneSelected ? (
        <>
          {isTemplate ? null : <ApplicationDetails title={title} setTitle={setTitle} desc={desc} setDesc={setDesc} />}
          {isTemplate ? null : <NewTemplate />}
          {isTemplate ? <TemplateDetails isTemplate={isTemplate} /> : null}
        </>
      ) : null}
      <div className="flex-1">
        {edgeElement ? <EdgeDetails elementId={edgeElement.id} /> : null}
        {nodeElement?.data ? <ElementDetails data={nodeElement.data} updateElement={updateElement} /> : null}
        {nodeElement?.type === FlowElementType.TemplateQns ? (
          <TemplateDetails isTemplate={isTemplate} data={nodeElement.data} />
        ) : null}
        {nodeElement?.type === FlowElementType.GroupQns ? (
          <GroupDetails data={nodeElement.data} updateElement={updateElement} />
        ) : null}
      </div>
      {selectedElement ? (
        <div className="py-2">
          <button
            onClick={() => removeElement()}
            className="appearance-none w-full px-2.5 py-1.5 text-rose-700 dark:text-rose-500 bg-white dark:bg-neutral-900 hover:text-rose-500 dark:hover:text-rose-600 focus:outline-none"
          >
            {isEdge(selectedElement) ? 'Delete edge' : 'Delete node'}
          </button>
        </div>
      ) : null}
    </div>
  )
}
