import Fuse from 'fuse.js'
import React, { DragEvent, FC, useCallback, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { DragDropData } from 'src/types'

import {
  dataComponents,
  endComponents,
  groupComponents,
  healthComponents,
  multiComponents,
  staticComponents,
} from './component-library/components'
import { ComponentsSection } from './component-library/components-section'
import { Templates } from './component-library/templates'

const fuse = new Fuse(
  [...dataComponents, ...healthComponents, ...multiComponents, ...staticComponents, ...endComponents],
  {
    keys: ['label'],
    shouldSort: false,
  },
)

export const ComponentLibrary: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  useHotkeys('cmd+k', () => searchInputRef.current?.focus())

  const onDragStart = useCallback((event: DragEvent<HTMLDivElement>, data: DragDropData) => {
    event.dataTransfer.setData('application/form-builder', JSON.stringify(data))
    event.dataTransfer.effectAllowed = 'move'
  }, [])

  const matchedComponentIds = fuse.search(searchTerm).map(({ item }) => item.id)

  return (
    <div id="components" className="flex flex-col flex-1 pb-4 space-y-4">
      <div className="sticky top-0 py-4 bg-white dark:bg-neutral-900">
        <h5 className="flex-shrink-0 mb-3 capitalize text-neutral-900 dark:text-neutral-400">Components library</h5>
        <div className="flex-shrink-0 space-y-1">
          <label htmlFor="search" className="font-medium sr-only text-neutral-700 dark:text-neutral-500">
            Quick search
          </label>
          <div className="relative flex items-center">
            <input
              type="text"
              name="search"
              ref={searchInputRef}
              value={searchTerm}
              onChange={event => setSearchTerm(event.target.value)}
              className="block w-full pr-12 shadow-sm border-neutral-300 dark:border-neutral-800 rounded-xs focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:bg-neutral-800"
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <kbd className="inline-flex items-center px-2 font-sans text-sm font-medium text-neutral-400 border border-neutral-200 dark:border-neutral-800 dark:bg-neutral-900 rounded-xs">
                ⌘K
              </kbd>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 space-y-8">
        <Templates onDragStart={onDragStart} searchTerm={searchTerm} />
        <ComponentsSection
          nodes={groupComponents.filter(({ id }) => (searchTerm ? matchedComponentIds.includes(id) : true))}
          title="Group components"
          onDragStart={onDragStart}
        />
        <ComponentsSection
          nodes={staticComponents.filter(({ id }) => (searchTerm ? matchedComponentIds.includes(id) : true))}
          title="Static components"
          onDragStart={onDragStart}
        />
        <ComponentsSection
          nodes={dataComponents.filter(({ id }) => (searchTerm ? matchedComponentIds.includes(id) : true))}
          title="Data components"
          onDragStart={onDragStart}
        />
        <ComponentsSection
          nodes={multiComponents.filter(({ id }) => (searchTerm ? matchedComponentIds.includes(id) : true))}
          title="Multi components"
          onDragStart={onDragStart}
        />
        <ComponentsSection
          nodes={healthComponents.filter(({ id }) => (searchTerm ? matchedComponentIds.includes(id) : true))}
          title="Health components"
          onDragStart={onDragStart}
        />
        <ComponentsSection
          nodes={endComponents.filter(({ id }) => (searchTerm ? matchedComponentIds.includes(id) : true))}
          title="End components"
          onDragStart={onDragStart}
        />
      </div>
    </div>
  )
}
