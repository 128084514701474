import { gsap } from 'gsap'
import { useEffect } from 'react'

export const useBubbleEntry = (id: string) => {
  useEffect(() => {
    if (!id) return
    const tl = gsap.from(`#${id}`, { opacity: 0, y: 32, autoAlpha: 0, duration: 0.5, ease: 'power2.out' })
    return () => {
      tl.kill()
    }
  }, [id])
}
