import { isEdge, Node } from 'react-flow-renderer/nocss'
import { DropdownOption } from 'src/components/dropdown-input'
import { ConditionType, EdgeConditionsArray, FlowElements, FlowElementType, NodeMeta } from 'src/types'

export const operationToOption = (operation: ConditionType) => {
  return { id: operation as string, name: operation as string }
}

export const elementToOption = (element: Node<NodeMeta>) => {
  return { id: element?.id, name: element?.data?.question || element?.type || '' }
}

const equalToOperation = [ConditionType.EqualTo].map(operationToOption)

export const operationOptionsMap: Record<keyof typeof FlowElementType, DropdownOption[]> = {
  [FlowElementType.SectionTitle]: equalToOperation,
  [FlowElementType.SectionBody]: equalToOperation,
  [FlowElementType.YesNoQn]: equalToOperation,
  [FlowElementType.DropdownQn]: equalToOperation,
  [FlowElementType.MultiSelectQn]: [ConditionType.SomeOf].map(operationToOption),
  [FlowElementType.ChecklistQn]: [ConditionType.SomeOf].map(operationToOption),
  [FlowElementType.TextInputQn]: equalToOperation,
  [FlowElementType.EmailInputQn]: equalToOperation,
  [FlowElementType.PhoneInputQn]: equalToOperation,
  [FlowElementType.NumberInputQn]: [ConditionType.EqualTo, ConditionType.GreaterThan, ConditionType.LessThan].map(
    operationToOption,
  ),
  [FlowElementType.DateInputQn]: [ConditionType.EqualTo, ConditionType.GreaterThan, ConditionType.LessThan].map(
    operationToOption,
  ),
  [FlowElementType.PrescriptionsQn]: equalToOperation,
  [FlowElementType.ConditionsQn]: equalToOperation,
  [FlowElementType.Approved]: [],
  [FlowElementType.Declined]: [],
  [FlowElementType.Initial]: [],
  [FlowElementType.SelectableEdge]: [],
  [FlowElementType.TemplateQns]: [],
  [FlowElementType.GroupQns]: equalToOperation,
  [FlowElementType.Signature]: [],
}

export const updateEdgeConditions = (
  currentElements: FlowElements,
  edgeId: string,
  updateConditions: (currentConditions: EdgeConditionsArray) => EdgeConditionsArray,
) => {
  return currentElements.map(currentElement => {
    if (!isEdge(currentElement) || currentElement.id !== edgeId) return currentElement
    const currentCondition = currentElement.data?.condition
    if (!currentCondition) return currentElement

    return {
      ...currentElement,
      data: {
        ...currentElement.data,
        condition: {
          ...currentCondition,
          conditions: updateConditions(currentCondition.conditions),
        },
      },
    }
  })
}
