import React, { FC } from 'react'
import { NodeComponentProps } from 'react-flow-renderer/nocss'
import { SelectInput } from 'src/components/select-input'
import { DropdownMeta } from 'src/types'

import { BaseQuestion } from './base-qn'

export const DropdownQn: FC<NodeComponentProps<DropdownMeta>> = props => {
  const { data } = props
  const options = data?.options
  return (
    <BaseQuestion {...props} label="dropdown question">
      <div className="p-4">
        <SelectInput
          name="location"
          disabled={!options}
          options={options || ['dropdown options']}
          defaultValue={(options && options[0]) || 'dropdown options'}
          className="text-input"
        ></SelectInput>
      </div>
    </BaseQuestion>
  )
}
