import { isDate, parse } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import { isEdge, isNode } from 'react-flow-renderer/nocss'
import { FormDesignOutput } from 'src/graphql-schema'
import {
  DropdownMeta,
  EdgeConditions,
  FlowElements,
  FlowElementType,
  isDateRangeMeta,
  isGroupData,
  isRangeMeta,
  isTemplateData,
  MachineStates,
  NodeMeta,
  TContext,
  TEvent,
} from 'src/types'
import { v4 } from 'uuid'
import { TransitionsConfigMap } from 'xstate'
import { AnySchema, date, number, object, string } from 'yup'

import { dateFormat } from './config'
import { duplicateElements } from './utils'

const textOnlyNodes = [FlowElementType.SectionTitle, FlowElementType.SectionBody] as string[]

const sortConditions = (transitions: any[]) => {
  return sortBy(transitions, transition => {
    if (transition.cond) {
      const conditions = transition.cond as EdgeConditions
      return conditions.conditions?.length || 0
    }
    return 0
  }).reverse()
}

export const elementsToStates = (elements: FlowElements, templates: FormDesignOutput[] = []) => {
  const states = elements.reduce((acc, element) => {
    if (isNode(element)) {
      set(acc, `${element.id}.id`, element.id)
      set(acc, `${element.id}.meta`, {
        ...(element.data || {}),
        id: element.id,
        type: element.type,
      })
      const elementData = element.data
      if (isTemplateData(elementData)) {
        const template = templates.find(t => t.id === elementData.entityId)
        const templateElements = template?.config.elements

        if (templateElements) {
          const templateInstanceElements = duplicateElements(templateElements)
          set(acc, `${element.id}.initial`, FlowElementType.Initial)
          set(acc, `${element.id}.states`, elementsToStates(templateInstanceElements, templates))
          set(acc, `${element.id}.states.history`, { id: v4(), type: 'history' })
        }
      }
      if (isGroupData(elementData)) {
        const groupElements = elementData.questions
        if (groupElements) {
          const totalQuestions = groupElements.length
          const questionStates = groupElements.reduce((qnAcc, question, index) => {
            const stateConfig = {
              id: question.id,
              meta: { question: question.question, type: question.type, id: question.id },
            }
            if (index > 0) set(stateConfig, 'on.PREV', groupElements[index - 1].id)
            if (index < totalQuestions - 1) set(stateConfig, 'on.NEXT', groupElements[index + 1].id)
            qnAcc[question.id] = stateConfig
            return qnAcc
          }, {} as NonNullable<MachineStates>)
          const groupInitial = v4()
          set(acc, `${element.id}.initial`, groupInitial)
          set(acc, `${element.id}.states`, {
            ...questionStates,
            [groupInitial]: {
              id: groupInitial,
              meta: {
                ...(element.data || {}),
              },
              always: { target: groupElements[0].id, cond: 'isCUI' },
            },
          })
          set(acc, `${element.id}.states.history`, { id: v4(), type: 'history' })
        }
      }
      set(acc, `${element.id}.on`, {})
    }
    return acc
  }, {} as NonNullable<MachineStates>)

  elements.forEach(element => {
    if (isEdge(element)) {
      const initialNode = element.source === FlowElementType.Initial

      if (element.source) {
        const stateConfig = states[element.source]

        if (initialNode) {
          stateConfig.always = element.target
          return
        }

        if (isGroupData(stateConfig.meta)) {
          if (isEmpty(stateConfig.meta.questions)) {
            // groups with no questions are skipped
            stateConfig.always = element.target
            return
          }
        }

        if (textOnlyNodes.includes(stateConfig.meta.type)) {
          stateConfig.after = { 100: { target: element.target } }
          return
        }
        const cond = element.data?.condition

        if (stateConfig.on) {
          const onConfig = stateConfig.on as TransitionsConfigMap<TContext, TEvent>
          const currentTransitions = onConfig.NEXT
          stateConfig.exit = ['saveValue']
          onConfig.NEXT = sortConditions([
            {
              target: element.target,
              ...(cond ? { cond } : {}),
            },
            ...(Array.isArray(currentTransitions) ? currentTransitions : []),
          ])
        }

        if (!initialNode) {
          const historyState = stateConfig.states?.history
          if (historyState) {
            set(states, `${element.target}.on.PREV`, `#${historyState.id}`)
          } else {
            set(states, `${element.target}.on.PREV`, element.source)
          }
        }
      }
    }
  })

  return states
}

function getInitialValue(type?: string): string | string[] | number | number[] | boolean | Date {
  if (type === FlowElementType.YesNoQn) return ''
  if (type === FlowElementType.DropdownQn) return ''
  if (type === FlowElementType.MultiSelectQn) return []
  return ''
}

export const elementsToInitialValues = (elements: FlowElements): Record<string, unknown> => {
  const initialValues: Record<string, unknown> = {}
  elements.forEach(element => {
    if (isNode(element)) {
      if (element.data && isGroupData(element.data)) {
        const questions = element.data.questions || []
        questions.forEach(question => {
          initialValues[question.id] = getInitialValue(question.type)
        })
      } else if (element.type !== FlowElementType.Initial && !textOnlyNodes.includes(element.type || '')) {
        initialValues[element.id] = getInitialValue(element.type)
      }
    }
  })
  return initialValues
}

function parseDateString(value: string, originalValue: Date | string) {
  const parsedDate = isDate(originalValue)
    ? (originalValue as Date)
    : parse(originalValue as string, dateFormat, new Date())

  return parsedDate
}

function getNodeSchema(type?: string, data?: NodeMeta) {
  if (type === FlowElementType.YesNoQn) return string().oneOf(['yes', 'no']).required()
  if (type === FlowElementType.DropdownQn)
    return string()
      .oneOf(data ? (data as unknown as DropdownMeta).options || [] : [])
      .required()
  if (type === FlowElementType.EmailInputQn) return string().email().required()
  if (type === FlowElementType.PhoneInputQn) {
    return string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, {
      excludeEmptyString: true,
    })
  }
  if (type === FlowElementType.NumberInputQn) {
    let numSchema = number().required()
    if (data && isRangeMeta(data)) {
      if (data?.min) numSchema = numSchema.min(data.min as number)
      if (data?.max) numSchema = numSchema.max(data.max as number)
    }
    return numSchema
  }
  if (type === FlowElementType.DateInputQn) {
    let dateSchema = date().transform(parseDateString).required()
    if (data && isDateRangeMeta(data)) {
      if (data.min) dateSchema = dateSchema.min(parseDateString(data.min, new Date()))
      if (data.max) dateSchema = dateSchema.max(parseDateString(data.max, new Date()))
    }
    return dateSchema
  }
  return string()
}

export const elementsToYupSchema = (elements: FlowElements) => {
  const schema: Record<string, AnySchema> = {}
  elements.forEach(element => {
    if (isNode(element)) {
      if (element.data && isGroupData(element.data)) {
        const questions = element.data.questions || []
        questions.forEach(question => {
          schema[question.id] = getNodeSchema(question.type, question)
        })
      } else {
        schema[element.id] = getNodeSchema(element.type, element.data)
      }
    }
  })
  return object(schema).required().defined()
}
