import React, { FC } from 'react'
import { SelectHTMLAttributes } from 'react'

interface SelectInputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: string[]
  showNone?: boolean
}
export const SelectInput: FC<SelectInputProps> = ({ options, showNone, ...props }) => {
  const cleanOptions = options.map(value => value.trim()).filter(Boolean)
  return (
    <select {...props}>
      {cleanOptions.map(option => (
        <option key={option}>{option}</option>
      ))}
      {showNone && <option value="">None</option>}
    </select>
  )
}
