import React, { FC } from 'react'
import { NodeComponentProps } from 'react-flow-renderer/nocss'
import { SelectInput } from 'src/components/select-input'
import { DropdownMeta } from 'src/types'

import { BaseQuestion } from './base-qn'

export const MultiSelectQn: FC<NodeComponentProps<DropdownMeta>> = props => {
  const { data } = props
  const options = data?.options
  return (
    <BaseQuestion {...props} label="multi select question">
      <div className="p-4">
        <SelectInput
          name="multi-select"
          disabled={!options}
          options={options || ['multi select options']}
          defaultValue={(options && options[0]) || 'multi select options'}
          className="text-input"
        ></SelectInput>
      </div>
    </BaseQuestion>
  )
}
