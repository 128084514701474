import { faPaperPlaneTop, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { faSignature } from '@fortawesome/pro-thin-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSvgDrawing } from '@svg-drawing/react'
import React, { FC } from 'react'
import { useFormikField } from 'src/hooks/use-formik-field'

interface SignatureControlProps {
  id: string
  onAnswered: (answer: string) => void
}

export const SignatureControls: FC<SignatureControlProps> = ({ id, onAnswered }) => {
  const { helpers } = useFormikField(id)
  const [renderRef, draw] = useSvgDrawing({ penWidth: 2 })

  const submitAnswer = () => {
    const svgSrc = draw.getSvgXML()
    if (!svgSrc) return
    draw.clear()
    helpers.setValue(svgSrc)
    helpers.setTouched(true)
    helpers.setError(undefined)
    requestAnimationFrame(() => onAnswered(svgSrc))
  }
  return (
    <div className="relative p-2 bg-neutral-100 dark:bg-neutral-700">
      <div className="absolute left-3 bottom-3">
        <FontAwesomeIcon icon={faSignature} className="h-8 text-neutral-500 dark:text-neutral-400" />
      </div>
      <div className="absolute right-3 top-3">
        <button onClick={() => draw.clear()} className="appearance-none">
          <FontAwesomeIcon
            icon={faTrashCan}
            className="h-4 text-neutral-500 dark:text-neutral-400 hover:text-neutral-900"
          />
        </button>
      </div>
      <div className="absolute right-3 bottom-3">
        <button onClick={submitAnswer} className="flex items-center justify-center flex-1 text-center appearance-none">
          <FontAwesomeIcon
            icon={faPaperPlaneTop}
            className="h-4 text-neutral-500 dark:text-neutral-400 hover:text-neutral-900"
          />
        </button>
      </div>
      <div className="h-16 border-b border-b-neutral-300 dark:border-b-neutral-600" ref={renderRef}></div>
    </div>
  )
}
