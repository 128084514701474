import { gsap } from 'gsap'
import { useEffect, useState } from 'react'

export const usePreviewMode = (initialState = false) => {
  const [previewMode, setPreviewMode] = useState<boolean>(initialState)

  useEffect(() => {
    if (previewMode) {
      const t1 = gsap.to('.canvas', { x: '-50%' })
      const t2 = gsap.to('#components-library', { x: '-100%', opacity: 0 })
      const t3 = gsap.to('#node-details', { x: '100%', opacity: 0 })
      return () => {
        t1.kill()
        t2.kill()
        t3.kill()
      }
    } else {
      const t1 = gsap.to('.canvas', { x: 0 })
      const t2 = gsap.to('#components-library', { x: '0%', opacity: 1 })
      const t3 = gsap.to('#node-details', { x: '0%', opacity: 1 })
      return () => {
        t1.kill()
        t2.kill()
        t3.kill()
      }
    }
  }, [previewMode])
  return [previewMode, setPreviewMode] as const
}
