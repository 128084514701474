import React, { FC, useContext, useState } from 'react'
import { Node } from 'react-flow-renderer/nocss'
import { createFormDesign } from 'src/backend/create-form-design'
import { ElementsContext, FlowContext, getStartNode } from 'src/common/utils'
import { ExpandableTextarea } from 'src/components/expandable-textarea'
import { DesignType } from 'src/graphql-schema'
import { FlowElementType, TemplateDragDropData } from 'src/types'
import { v4 } from 'uuid'

export const NewTemplate: FC = () => {
  const [isCreating, setIsCreating] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { elements, setElements } = useContext(ElementsContext)
  const flowInstance = useContext(FlowContext)
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const canCreateTemplate = elements.length > 2

  const createTemplate = async () => {
    const flow = flowInstance?.toObject()
    setIsCreating(true)
    setHasError(false)
    try {
      const newTemplate = await createFormDesign(
        {
          data: { name, type: DesignType.Template, config: { elements: flow?.elements || [getStartNode()] } },
        },
        DesignType.Template,
      )
      if (newTemplate) {
        const startNode = getStartNode()
        const templateNode: Node<TemplateDragDropData> = {
          id: v4(),
          type: FlowElementType.TemplateQns,
          position: { x: 0, y: 150 },
          data: {
            title,
            id: newTemplate.id,
            entityId: newTemplate.id,
            name: newTemplate.name,
            type: FlowElementType.TemplateQns,
          },
        }
        setElements([startNode, templateNode])
      }
    } catch (error) {
      setHasError(true)
    } finally {
      setIsCreating(false)
    }
  }
  if (!canCreateTemplate) return null
  return (
    <div className="px-3 py-4">
      <h5 className="mb-3 tracking-wide capitalize text-neutral-700 dark:text-neutral-400">new template</h5>
      <div className="mt-2 space-y-4">
        <ExpandableTextarea
          name="template-name"
          value={name}
          label="Name"
          required
          onChange={event => setName(event.target.value)}
          placeholder="Template name"
          className="text-input"
          autoComplete="off"
          spellCheck={false}
        />
        <ExpandableTextarea
          name="template-title"
          value={title}
          label="Title"
          onChange={event => setTitle(event.target.value)}
          placeholder="Title"
          className="text-input"
          autoComplete="off"
          spellCheck={false}
        />
        <button onClick={createTemplate} className="btn" disabled={isCreating || !name}>
          create template
        </button>
      </div>
    </div>
  )
}
