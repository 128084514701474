import React, { FC } from 'react'
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer/nocss'
import { classNames } from 'src/common/utils'
import { TargetHandle } from 'src/components/target-handle'

export const Approved: FC<NodeComponentProps> = ({ id, selected, data = {}, children }) => {
  return (
    <div
      className={classNames(
        'border rounded-lg w-80',
        selected
          ? 'shadow-lg shadow-green-700/20 border-green-700 ring-1 ring-green-600 bg-green-50 dark:bg-green-900'
          : 'bg-white dark:bg-neutral-800 shadow border-green-600',
      )}
    >
      <TargetHandle />
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-1 text-neutral-900 dark:text-green-400">
          {data.name ? (
            <span>{data.name}</span>
          ) : (
            <>
              <span className="hidden">{`Q-${id} :`}</span>
              <span className="text-green-700 capitalize dark:text-green-300">Approved</span>
            </>
          )}
        </div>
      </div>
      <div className="px-4 py-2">
        <p
          className={classNames(
            'line-clamp-4',
            data.question
              ? ' text-green-900 dark:text-green-300'
              : 'text-neutral-600 dark:text-neutral-400 font-light italic',
          )}
        >
          {data.question || `Approved message`}
        </p>
      </div>
      {children}
      <div className="relative z-10 flex items-center justify-center p-2">
        <Handle
          type="source"
          position={Position.Bottom}
          className="w-3 h-3 border-2 bottom-[-6px] border-white dark:border-neutral-900 rounded-full bg-green-700"
        />
      </div>
    </div>
  )
}
