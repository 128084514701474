import { faLaptop, faMoon, faSunBright } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Transition } from '@headlessui/react'
import React, { FC, Fragment } from 'react'
import { classNames } from 'src/common/utils'
import { useDarkMode } from 'src/hooks/use-dark-mode'

interface ItemProps {
  active: boolean
}

export const DarkMode: FC = () => {
  const [enabledState, setEnabledState] = useDarkMode()
  let icon = faLaptop
  if (enabledState === true) icon = faMoon
  if (enabledState === false) icon = faSunBright
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button>
        <FontAwesomeIcon icon={icon} className="w-4 h-4" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 w-24 mt-2 origin-top-right bg-white divide-y rounded-md shadow-lg dark:bg-neutral-800 divide-neutral-100 dark:divide-neutral-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }: ItemProps) => (
                <button
                  onClick={() => setEnabledState(true)}
                  className={classNames(
                    'appearance-none group flex rounded-md items-center w-full px-2 py-2 space-x-2',
                    active ? 'bg-neutral-50 dark:bg-neutral-700' : 'bg-white dark:bg-neutral-800',
                  )}
                >
                  <FontAwesomeIcon icon={faMoon} className={`h-4 w-4`} />
                  <span>Dark</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: ItemProps) => (
                <button
                  onClick={() => setEnabledState(false)}
                  className={classNames(
                    'appearance-none group flex rounded-md items-center w-full px-2 py-2 space-x-2',
                    active ? 'bg-neutral-50 dark:bg-neutral-700' : 'bg-white dark:bg-neutral-800',
                  )}
                >
                  <FontAwesomeIcon icon={faSunBright} className={`h-4 w-4`} />
                  <span>Light</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: ItemProps) => (
                <button
                  onClick={() => setEnabledState(undefined)}
                  className={classNames(
                    'appearance-none group flex rounded-md items-center w-full px-2 py-2 space-x-2',
                    active ? 'bg-neutral-50 dark:bg-neutral-700' : 'bg-white dark:bg-neutral-800',
                  )}
                >
                  <FontAwesomeIcon icon={faLaptop} className={`h-4 w-4`} />
                  <span>System</span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
