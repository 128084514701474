import { navigate } from 'gatsby'
import React, { FC, useState } from 'react'
import { updateFormDesign } from 'src/backend/update-form-design'
import { ExpandableTextarea } from 'src/components/expandable-textarea'
import { DesignType } from 'src/graphql-schema'
import { TemplateDragDropData } from 'src/types'

interface TemplateDetailsProps {
  data?: TemplateDragDropData
  isTemplate?: boolean
}

export const TemplateDetails: FC<TemplateDetailsProps> = ({ data, isTemplate }) => {
  const templateId = data?.entityId
  const [name, setName] = useState(data?.name || '')
  const [title, setTitle] = useState(data?.title || '')

  const updateTemplate = () => {
    if (templateId) {
      navigate(`/designs/${templateId}/editor`)
    }
  }

  return (
    <div className="px-3 py-4">
      <h5 className="mb-3 tracking-wide capitalize text-neutral-700 dark:text-neutral-400">template details</h5>
      <div className="mt-2 space-y-4">
        {isTemplate ? (
          <ExpandableTextarea
            name="template-name"
            value={name}
            label="Name"
            required
            onChange={event => setName(event.target.value)}
            onBlur={async () => {
              if (name !== data?.name && templateId) {
                try {
                  await updateFormDesign({ data: { id: templateId, name } }, DesignType.Template)
                  // eslint-disable-next-line no-empty
                } catch {}
              }
            }}
            placeholder="Template name"
            className="block w-full shadow-sm resize-none border-neutral-300 dark:text-neutral-300 dark:border-neutral-700 dark:bg-neutral-800 rounded-xs focus:ring-sky-500 focus:border-sky-500"
            autoComplete="off"
            spellCheck={false}
          />
        ) : null}
        {isTemplate ? (
          <ExpandableTextarea
            name="template-title"
            value={title}
            label="Title"
            onChange={event => setTitle(event.target.value)}
            onBlur={async () => {
              if (title !== data?.title && templateId) {
                try {
                  await updateFormDesign({ data: { id: templateId, title } }, DesignType.Template)
                  // eslint-disable-next-line no-empty
                } catch {}
              }
            }}
            placeholder="Title"
            className="block w-full shadow-sm resize-none border-neutral-300 dark:text-neutral-300 dark:border-neutral-700 dark:bg-neutral-800 rounded-xs focus:ring-sky-500 focus:border-sky-500"
            autoComplete="off"
            spellCheck={false}
          />
        ) : null}
        {isTemplate ? null : (
          <button
            onClick={updateTemplate}
            className="w-full p-2 font-medium capitalize bg-white border shadow-sm appearance-none text-neutral-700 border-neutral-300 dark:text-neutral-400 dark:bg-neutral-800 dark:border-neutral-700 rounded-xs hover:bg-neutral-50 dark:hover:bg-neutral-900 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-neutral-500"
          >
            update template
          </button>
        )}
      </div>
    </div>
  )
}
