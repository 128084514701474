import { useEffect, useState } from 'react'

export function useDarkColorScheme() {
  const mediaQuery = typeof window === 'undefined' ? undefined : window.matchMedia('(prefers-color-scheme: dark)')

  const [value, setValue] = useState(!!mediaQuery?.matches)

  useEffect(
    () => {
      if (typeof window === 'undefined') return
      if (!mediaQuery) return
      const handler = () => setValue(mediaQuery.matches)
      mediaQuery.addEventListener('change', handler)
      return () => mediaQuery.removeEventListener('change', handler)
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )
  return value
}
