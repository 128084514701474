import React, { FC } from 'react'
import { EdgeProps, getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer/nocss'
import { ConditionType, EdgeMeta } from 'src/types'

export const SelectableEdge: FC<EdgeProps<EdgeMeta>> = props => {
  const { id, data, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, arrowHeadType, markerEndId } =
    props

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })
  const edgeCondition = data?.condition
  let conditionValue = ''
  if (edgeCondition?.conditions) {
    const totalConditions = edgeCondition.conditions.length
    if (totalConditions > 1) {
      conditionValue = `${totalConditions} rules`
    } else {
      const condition = edgeCondition.conditions[0]
      if (!condition) {
        conditionValue = ''
      } else if (condition.type === ConditionType.EqualTo) {
        conditionValue = condition.value
      } else if (condition.type === ConditionType.SomeOf) {
        conditionValue = condition.values.join(', ')
      }
    }
  }

  return (
    <g id={id}>
      <path className="react-flow__edge-path-selector" d={edgePath} markerEnd={markerEnd} fillRule="evenodd" />
      <path className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} fillRule="evenodd" />
      {edgeCondition && (
        <foreignObject
          width={96}
          height={32}
          x={edgeCenterX - 48}
          y={edgeCenterY - 16}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          {conditionValue ? (
            <div className="flex items-center justify-center p-2 text-white rounded-sm bg-sky-600 dark:bg-sky-700">
              <span className="flex-1 overflow-hidden leading-none text-center cursor-default line-clamp-1">
                {conditionValue}
              </span>
            </div>
          ) : null}
        </foreignObject>
      )}
    </g>
  )
}
