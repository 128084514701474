import React, { FC } from 'react'
import { classNames } from 'src/common/utils'
import { useFormikField } from 'src/hooks/use-formik-field'

interface YesNoControlProps {
  id: string
  onAnswered: (answer: 'yes' | 'no') => void
}

export const YesNoControls: FC<YesNoControlProps> = ({ id, onAnswered }) => {
  const { helpers } = useFormikField(id)

  const submitAnswer = (value: 'yes' | 'no') => {
    return () => {
      helpers.setValue(value)
      helpers.setTouched(true)
      helpers.setError(undefined)
      requestAnimationFrame(() => onAnswered(value))
    }
  }
  return (
    <div className="flex space-x-6">
      <button
        onClick={submitAnswer('yes')}
        className={classNames(
          'p-4 flex-1 text-white dark:text-neutral-200 rounded appearance-none bg-neutral-400 hover:bg-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-900 flex items-center justify-center text-center',
        )}
      >
        Yes
      </button>
      <button
        onClick={submitAnswer('no')}
        className={classNames(
          'p-4 flex-1 text-white dark:text-neutral-200 rounded appearance-none bg-neutral-400 hover:bg-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-900 flex items-center justify-center text-center',
        )}
      >
        No
      </button>
    </div>
  )
}
