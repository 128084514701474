import React, { FC, useState } from 'react'
import { ExpandableTextarea } from 'src/components/expandable-textarea'
import { DropdownMeta } from 'src/types'

import { BaseDetails } from './base-qn-details'

interface DropdownDetailsProps {
  data: DropdownMeta
  updateElement: (data: DropdownMeta) => void
}
export const DropdownDetails: FC<DropdownDetailsProps> = ({ data, updateElement }) => {
  const [options, setOptions] = useState(data.options || [])

  return (
    <BaseDetails data={data} updateElement={updateElement}>
      <div className="my-4">
        <div>
          <label htmlFor="options" className="block font-medium text-neutral-700">
            Options (separated by newline)
          </label>
          <div className="mt-1">
            <ExpandableTextarea
              name="options"
              value={options.join('\n')}
              onChange={event => {
                const value = event.target.value.split('\n')
                setOptions(value)
                updateElement({
                  ...data,
                  options: value,
                })
              }}
              className="text-input"
            />
          </div>
        </div>
      </div>
    </BaseDetails>
  )
}
