import React, { FC } from 'react'
import { Handle, Position } from 'react-flow-renderer/nocss'

export const TargetHandle: FC = () => {
  return (
    <Handle
      type="target"
      position={Position.Top}
      className="w-3 h-3 border-2 top-[-6px] border-white dark:border-neutral-900 rounded-full bg-sky-700 dark:bg-sky-800"
    />
  )
}
