import { faArrowUpFromLine, faDraftingCompass, faPlay } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import React, { FC, useState } from 'react'
import { updateFormDesign } from 'src/backend/update-form-design'
import { elementsToStates } from 'src/common/transformers'
import { DarkMode } from 'src/components/dark-mode'
import { DesignType } from 'src/graphql-schema'
import { FlowElements } from 'src/types'

import { Logo } from './logo'

interface EditorHeaderProps {
  previewMode: boolean
  setPreviewMode: (previewMode: boolean) => void
  elements: FlowElements
  id?: string
  name?: string
}

export const EditorHeader: FC<EditorHeaderProps> = ({ id, name, previewMode, setPreviewMode, elements }) => {
  const [fileName, setFileName] = useState(name || 'Untitled')

  const downloadFile = () => {
    const stateConfig = elementsToStates(elements || [])
    const json = JSON.stringify(stateConfig, null, 2)
    const link = document.createElement('a')
    link.href = `data:text/json;charset=utf-8,${encodeURIComponent(json)}`
    link.download = 'schema.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <header className="h-[60px] flex-none text-sm leading-6 bg-white dark:bg-neutral-900 border-b border-neutral-200 dark:border-neutral-800">
      <nav aria-label="Global" className="px-4">
        <div className="flex items-center py-3">
          <div className="flex-1">
            <button onClick={() => navigate('/designs')} className="appearance-none">
              <Logo className="h-8" />
            </button>
          </div>
          <div className="flex items-center justify-center flex-1">
            <input
              type="text"
              value={fileName}
              onChange={e => setFileName(e.target.value)}
              onBlur={async () => {
                if (fileName !== name && id) {
                  try {
                    await updateFormDesign({ data: { id, name: fileName } }, DesignType.Template)
                    // eslint-disable-next-line no-empty
                  } catch {}
                }
              }}
              className="text-sm text-center bg-transparent border-0 text-neutral-900 dark:text-neutral-300 ring-0"
            />
          </div>
          <div className="flex items-center justify-end flex-1">
            <div className="flex items-center space-x-4">
              <DarkMode />
              {previewMode ? (
                <button type="button" title="Design" onClick={() => setPreviewMode(false)}>
                  <FontAwesomeIcon icon={faDraftingCompass} />
                </button>
              ) : (
                <button type="button" title="Preview" onClick={() => setPreviewMode(true)}>
                  <FontAwesomeIcon icon={faPlay} />
                </button>
              )}
              <button type="button" title="Publish" onClick={downloadFile}>
                <FontAwesomeIcon icon={faArrowUpFromLine} />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
