import { faCheck, faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox, Transition } from '@headlessui/react'
import React, { FC, Fragment } from 'react'
import { classNames } from 'src/common/utils'

export type DropdownOption = { id: string; name: string }

interface DropdownInputProps {
  label: string
  options: DropdownOption[]
  selectedOption?: DropdownOption
  setSelectedOption: (option: DropdownOption) => void
}

export const DropdownInput: FC<DropdownInputProps> = ({ label, options, selectedOption, setSelectedOption }) => {
  return (
    <Listbox value={selectedOption} onChange={setSelectedOption}>
      {({ open }) => (
        <div className="flex flex-col">
          <Listbox.Label className="block font-medium text-neutral-500 dark:text-neutral-400">{label}</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border cursor-default dark:bg-neutral-800 border-neutral-300 dark:border-neutral-700 focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 dark:focus:ring-sky-700 dark:focus:border-sky-700 sm:text-sm">
              <span className="block text-neutral-900 dark:text-neutral-400">
                {selectedOption?.name || 'select --'}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="w-4 h-4 text-neutral-900 dark:text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg dark:bg-neutral-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map(option => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-sky-600 dark:bg-sky-700' : 'text-neutral-900 dark:text-neutral-400',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : '', 'block truncate')}>
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white dark:text-neutral-900' : 'text-sky-600 dark:text-sky-700',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <FontAwesomeIcon icon={faCheck} className="w-4 h-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
