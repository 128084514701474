import identity from 'lodash/identity'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useRef } from 'react'
import { updateFormDesign } from 'src/backend/update-form-design'
import { isBrowser } from 'src/common/utils'
import { FlowElements } from 'src/types'

import { useDebounce } from './use-debounce'

export function useAutoSave(elements: FlowElements, id?: string) {
  const debouncedElements = useDebounce(elements, 10000)
  const isMounted = useRef(false)
  const savedElements = useRef(elements)
  const savedId = useRef(id)

  savedElements.current = elements
  useEffect(() => {
    if (!isBrowser() || !id) return
    if (isEmpty(debouncedElements)) return
    if (isMounted.current) {
      const thumbnail = document.getElementById('thumbnail-preview')?.innerHTML
      updateFormDesign({ data: { id, config: { elements: debouncedElements, thumbnail } } }).catch(identity)
    } else {
      isMounted.current = true
    }
  }, [debouncedElements, id])

  useEffect(
    () => () => {
      if (!savedId.current) return
      updateFormDesign({ data: { id: savedId.current, config: { elements: savedElements.current } } }).catch(identity)
    },
    [],
  )
}
