import differenceInYears from 'date-fns/differenceInYears'
import parse from 'date-fns/parse'
import every from 'lodash/every'
import intersection from 'lodash/intersection'
import some from 'lodash/some'
import { dateFormat } from 'src/common/config'
import {
  EdgeConditions,
  EqualToCondition,
  FormInterface,
  LessThanCondition,
  SomeOfCondition,
  TContext,
  TEvent,
} from 'src/types'
import { assign, ConditionPredicate } from 'xstate'

const saveValue = assign<TContext, TEvent>((context, event) => {
  if (event.type !== 'NEXT') return context
  return {
    answers: {
      ...context.answers,
      [event.key]: event.value || event.values,
    },
  }
})

const equalTo: ConditionPredicate<TContext, TEvent> = (context, event, { cond }) => {
  if (event.type !== 'NEXT') return true
  const { value, source } = cond as EqualToCondition
  if (!value || !source) return true // This is an incomplete condition, so we can't validate it
  if (source === 'event') return event.value === value
  return context.answers[source] === value
}

const greaterThan: ConditionPredicate<TContext, TEvent> = (context, event, { cond }) => {
  if (event.type !== 'NEXT') return true
  const { value, source } = cond as EqualToCondition
  if (!value || !source) return true // This is an incomplete condition, so we can't validate it
  const valueToCompare = source === 'event' ? event.value : (context.answers[source] as string)
  if (!valueToCompare) return true // the user may not have answered this question
  const dateToCompare = parse(valueToCompare, dateFormat, new Date())
  if (dateToCompare.getTime()) {
    if (+value) {
      // compare years
      const yearsPassed = differenceInYears(new Date(), dateToCompare)
      return yearsPassed > +value
    } else {
      const enteredDate = parse(value, dateFormat, new Date())
      return dateToCompare.getTime() > enteredDate.getTime()
    }
  } else {
    return +valueToCompare > +value
  }
}

const lessThan: ConditionPredicate<TContext, TEvent> = (context, event, { cond }) => {
  if (event.type !== 'NEXT') return true
  const { value, source } = cond as LessThanCondition
  if (!value || !source) return true // This is an incomplete condition, so we can't validate it
  const valueToCompare = source === 'event' ? event.value : (context.answers[source] as string)
  if (!valueToCompare) return true // the user may not have answered this question
  const dateToCompare = parse(valueToCompare, dateFormat, new Date())
  if (dateToCompare.getTime()) {
    if (+value) {
      // compare years
      const yearsPassed = differenceInYears(new Date(), dateToCompare)
      return yearsPassed < +value
    } else {
      const enteredDate = parse(value, dateFormat, new Date())
      return dateToCompare.getTime() < enteredDate.getTime()
    }
  } else {
    return +valueToCompare < +value
  }
}

const someOf: ConditionPredicate<TContext, TEvent> = (context, event, { cond }) => {
  if (event.type !== 'NEXT') return true
  const { values, source } = cond as SomeOfCondition
  if (!values || values.length === 0 || !source) return true // This is an incomplete condition, so we can't validate it

  const valuesToMatch = source === 'event' ? event.values : context.answers[source]
  if (Array.isArray(values) && Array.isArray(valuesToMatch)) {
    const matchValues = values.map(value => `${value}`.toLowerCase().trim())
    const eventValues = valuesToMatch.map(value => `${value}`.toLowerCase().trim())
    const intersectingValues = intersection(matchValues, eventValues)
    return intersectingValues.length > 0
  }
  return false
}

const strict: ConditionPredicate<TContext, TEvent> = (context, event, meta) => {
  const { cond, state } = meta
  const { conditions } = cond as EdgeConditions
  return every(conditions, condition => {
    const guard = state.machine?.options?.guards[condition.type]
    return guard ? guard(context, event, { ...meta, cond: condition }) : false
  })
}

const loose: ConditionPredicate<TContext, TEvent> = (context, event, meta) => {
  const { cond, state } = meta
  const { conditions } = cond as EdgeConditions
  return some(conditions, condition => {
    const guard = state.machine?.options?.guards[condition.type]
    return guard ? guard(context, event, { ...meta, cond: condition }) : false
  })
}

const isCUI: ConditionPredicate<TContext, TEvent> = context => {
  return context.interface === FormInterface.CUI
}

const isFUI: ConditionPredicate<TContext, TEvent> = context => {
  return context.interface === FormInterface.FUI
}

export const guards = { strict, loose, equalTo, greaterThan, lessThan, someOf, isCUI, isFUI }
export const actions = { saveValue }
