import React, { FC } from 'react'

interface ConditionsTypeProps {
  conditionType: 'strict' | 'loose'
  toggleConditionType: () => void
}
export const ConditionsType: FC<ConditionsTypeProps> = ({ conditionType, toggleConditionType }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-neutral-300 dark:border-neutral-800" />
      </div>
      <div className="relative flex justify-center">
        <button
          type="button"
          onClick={toggleConditionType}
          className="inline-flex items-center px-2 py-1 text-sm leading-5 text-neutral-700 bg-white border border-neutral-300 shadow-sm dark:border-neutral-800 dark:text-neutral-400 dark:bg-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
        >
          <span className="uppercase text-xxs">{conditionType === 'strict' ? 'and' : 'or'}</span>
        </button>
      </div>
    </div>
  )
}
