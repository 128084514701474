import React, { FC, ReactNode } from 'react'
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer/nocss'
import { classNames, getNodeBorderClasses } from 'src/common/utils'
import { TargetHandle } from 'src/components/target-handle'

interface BaseQuestionProps extends NodeComponentProps {
  label: string
  hideSourceHandle?: boolean
  placeholder?: string
  children?: ReactNode
}

export const BaseQuestion: FC<BaseQuestionProps> = ({
  id,
  label,
  selected,
  data,
  placeholder = 'Add question',
  hideSourceHandle = false,
  children,
}) => {
  return (
    <div className={getNodeBorderClasses(selected)}>
      <TargetHandle />
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-1 text-neutral-900 dark:text-neutral-400">
          {data.name ? (
            <span>{data.name}</span>
          ) : (
            <>
              <span className="hidden">{`Q-${id} :`}</span>
              <span className="capitalize">{label}</span>
            </>
          )}
        </div>
      </div>
      <div className="px-4 py-2">
        <p
          className={classNames(
            'line-clamp-4',
            data.question
              ? 'text-neutral-900 dark:text-neutral-100 leading-relaxed'
              : 'text-neutral-600 dark:text-neutral-700 font-light italic',
          )}
        >
          {data.question || placeholder}
        </p>
      </div>
      {children}
      {hideSourceHandle ? null : (
        <div className="relative z-10 flex items-center justify-center p-2">
          <Handle
            type="source"
            position={Position.Bottom}
            className="w-3 h-3 border-2 bottom-[-6px] border-white dark:border-neutral-900 rounded-full bg-sky-700 dark:bg-sky-800"
          />
        </div>
      )}
    </div>
  )
}
