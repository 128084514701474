import { faCheck, faSort } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox, Transition } from '@headlessui/react'
import React, { FC, Fragment } from 'react'
import { classNames } from 'src/common/utils'
import { useFormikField } from 'src/hooks/use-formik-field'

interface DropdownQnProps {
  id: string
  options: string[]
  onAnswered: (answer: string) => void
}

export const DropdownControls: FC<DropdownQnProps> = ({ id, onAnswered, options = [] }) => {
  const { field, helpers } = useFormikField(id)

  const submitAnswer = (value: string) => {
    helpers.setValue(value)
    helpers.setTouched(true)
    helpers.setError(undefined)
    requestAnimationFrame(() => onAnswered(value))
  }
  return (
    <div className="">
      <Listbox value={field.value} onChange={submitAnswer}>
        {({ open }) => (
          <>
            <div className="">
              <div className="relative">
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute bottom-0 z-10 w-full py-1 mb-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-neutral-700 bottom- max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {options.map(option => (
                      <Listbox.Option
                        key={option}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'dark:text-neutral-200 bg-neutral-400 dark:bg-neutral-900'
                              : 'text-neutral-900 dark:text-neutral-300',
                            'cursor-default select-none relative py-2 pl-8 pr-4',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : '', 'block truncate')}>
                              {option}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : '',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                )}
                              >
                                <FontAwesomeIcon icon={faCheck} className="w-5 h-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-md shadow-sm cursor-default dark:bg-neutral-800 dark:border-neutral-700 border-neutral-300 focus:outline-none focus:ring-1 focus:ring-neutral-500 focus:border-neutral-500">
                <span className="block truncate">{field.value || 'Select'}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <FontAwesomeIcon
                    icon={faSort}
                    className="w-5 h-5 text-neutral-400 dark:text-neutral-300"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
