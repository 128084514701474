import { faMinus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useContext, useEffect, useState } from 'react'
import { Edge, Node } from 'react-flow-renderer/nocss'
import { ElementsContext, findNodeById, getAncestors, nodesToOptions } from 'src/common/utils'
import { DropdownInput } from 'src/components/dropdown-input'
import {
  ConditionType,
  EdgeCompareCondition,
  EdgeMeta,
  EqualToCondition,
  FlowElementType,
  GreaterThanCondition,
  LessThanCondition,
  NodeMeta,
  SomeOfCondition,
} from 'src/types'

import { ConditionDetails } from './condition-details'
import { SomeOfEdgeDetails } from './some-of-edge-details'
import { operationOptionsMap, updateEdgeConditions } from './utils'

interface EdgeConditionsDetailsProps {
  element: Edge<EdgeMeta>
  sourceElement: Node<NodeMeta>
  condition: EqualToCondition | SomeOfCondition | GreaterThanCondition | LessThanCondition
}
export const EdgeConditionsDetails: FC<EdgeConditionsDetailsProps> = ({ element, sourceElement, condition }) => {
  const { elements, setElements } = useContext(ElementsContext)
  const conditionSourceElement = findNodeById(
    elements,
    condition.source === 'event' ? element.source : condition.source,
  )

  const [optionToCompare, setOptionToCompare] = useState(
    nodesToOptions(conditionSourceElement ? [conditionSourceElement] : [])[0],
  )
  const elementToCompare = findNodeById(elements, optionToCompare?.id)

  const operationOptions = elementToCompare?.type ? operationOptionsMap[elementToCompare.type as FlowElementType] : []

  const [operation, setOperation] = useState(
    operationOptions.find(({ id }) => id === condition.type) || operationOptions[0],
  )

  const ancestors = getAncestors(elements, sourceElement)

  const sourceOptions = nodesToOptions(ancestors).filter(({ name }) => !!name)

  const removeCondition = () => {
    setElements(currentElements => {
      return updateEdgeConditions(currentElements, element.id, currentConditions =>
        currentConditions.filter(currentCondition => currentCondition.id !== condition.id),
      )
    })
  }

  const isParentElement = !elementToCompare || elementToCompare.id === element.source
  const source = isParentElement ? 'event' : elementToCompare.id

  useEffect(() => {
    setElements(currentElements => {
      return updateEdgeConditions(currentElements, element.id, currentConditions =>
        currentConditions.map(currentCondition => {
          if (currentCondition.id !== condition.id) return currentCondition
          return {
            ...currentCondition,
            source,
            type: operation.id as any,
          }
        }),
      )
    })
  }, [condition.id, source, operation.id, element.id, setElements])

  if (!conditionSourceElement || !elementToCompare) return null
  if (conditionSourceElement.type === FlowElementType.Initial && ancestors.length === 0) return null

  return (
    <div className="flex py-2">
      <div className="flex-1 space-y-4">
        <DropdownInput
          options={sourceOptions}
          label="node to compare"
          selectedOption={optionToCompare}
          setSelectedOption={setOptionToCompare}
        />
        <DropdownInput
          options={operationOptions}
          label="operation"
          selectedOption={operation}
          setSelectedOption={setOperation}
        />
        {[ConditionType.EqualTo, ConditionType.GreaterThan, ConditionType.LessThan].includes(
          operation.id as ConditionType,
        ) ? (
          <ConditionDetails
            element={element}
            sourceElement={elementToCompare}
            condition={condition as EdgeCompareCondition}
          />
        ) : null}
        {operation.id === ConditionType.SomeOf ? (
          <SomeOfEdgeDetails
            element={element}
            sourceElement={elementToCompare}
            condition={condition as SomeOfCondition}
          />
        ) : null}
      </div>
      <div className="flex-shrink-0">
        <button className="px-2 appearance-none" onClick={removeCondition}>
          <FontAwesomeIcon
            icon={faMinus}
            className="w-4 h-4 text-neutral-500 dark:text-neutral-400 hover:text-neutral-900 dark:hover:text-neutral-300"
          />
        </button>
      </div>
    </div>
  )
}
