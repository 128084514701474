import React, { FC, TextareaHTMLAttributes } from 'react'
import Textarea from 'react-textarea-autosize'

interface ExpandableTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
}

export const ExpandableTextarea: FC<ExpandableTextareaProps> = ({ label, name, required, ...props }) => {
  return (
    <div>
      {label ? (
        <label htmlFor="desc" className="block mb-1 text-neutral-500 dark:text-neutral-400">
          {label}
          {required ? <sup className="font-semibold text-rose-500">*</sup> : null}
        </label>
      ) : null}
      <Textarea
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        maxRows={5}
        name={name}
        required={required}
        placeholder={props.placeholder}
        className={props.className}
        spellCheck={props.spellCheck}
      />
    </div>
  )
}
