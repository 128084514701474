import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import compact from 'lodash/compact'
import intersection from 'lodash/intersection'
import React, { FC, useState } from 'react'
import { classNames } from 'src/common/utils'
import { useFormikField } from 'src/hooks/use-formik-field'
import { ExclusionsListItem, Outcome } from 'src/types'

const getOutcome = (selectedValues: string[], exclusionsList: ExclusionsListItem[]): Outcome => {
  const outcome: Outcome = 'approved'
  const outcomes = compact(
    exclusionsList.map(({ items, outcome }) => {
      const common = intersection(selectedValues, items)
      if (common.length === items.length) return outcome
      return null
    }),
  )
  const declined = outcomes.includes('declined')
  if (declined) return 'declined'
  const needsReview = outcomes.includes('review')
  if (needsReview) return 'review'
  return outcome
}

interface PrescriptionsControlProps {
  id: string
  type?: string
  placeholder?: string
  exclusionsList: ExclusionsListItem[]
  onAnswered: (answer: string) => void
}

export const PrescriptionsControls: FC<PrescriptionsControlProps> = ({
  id,
  onAnswered,
  type = 'text',
  exclusionsList,
  placeholder,
}) => {
  const { field, helpers } = useFormikField(id)
  const [value, setValue] = useState(field.value || '')
  const [error, setError] = useState<string>()

  const submitAnswer = async () => {
    const res = (await helpers.setValue(value)) as unknown as Record<string, string>
    const error = res[id]
    if (error) return setError(error)
    setError(undefined)
    helpers.setTouched(true)
    requestAnimationFrame(() => {
      const outcome = getOutcome(
        value.split(',').map((val: string) => val.trim().toLowerCase()),
        exclusionsList,
      )
      onAnswered(outcome)
    })
  }
  return (
    <div className="flex space-x-2">
      <input
        type={type}
        name={id}
        id={`${id}-input`}
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        className={classNames(
          'block w-full rounded-md shadow-sm appearance-none text-sm dark:bg-neutral-800 dark:text-neutral-200',
          error
            ? 'border-rose-500 focus:ring-rose-500 focus:border-rose-500'
            : 'border-neutral-300 dark:border-neutral-700 focus:ring-sky-500 focus:border-sky-500',
        )}
      />
      <button
        onClick={submitAnswer}
        className="flex items-center justify-center flex-1 p-4 text-center rounded appearance-none text-neutral-900 dark:text-neutral-200 bg-neutral-400 hover:bg-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-900"
      >
        <FontAwesomeIcon icon={faPaperPlaneTop} className="text-white dark:text-neutral-200" size="lg" />
      </button>
    </div>
  )
}
