import isArray from 'lodash/isArray'
import React, { FC } from 'react'
import { classNames } from 'src/common/utils'
import { useBubbleEntry } from 'src/hooks/use-bubble-entry'
import { useFormikField } from 'src/hooks/use-formik-field'

interface QuestionBubbleProps {
  id: string
  question: string
}

export const QuestionBubble: FC<Pick<QuestionBubbleProps, 'id' | 'question'>> = ({ id, question }) => {
  const { field, meta } = useFormikField(id)

  const answered = meta.touched && field.value !== undefined

  const qnId = `qn-${id}`
  const ansId = `ans-${id}`

  useBubbleEntry(qnId)
  useBubbleEntry(ansId)

  return (
    <section className="px-4 my-4 space-y-2">
      <div id={qnId}>
        <p className="inline-block p-4 m-0 text-neutral-900 dark:text-neutral-200 rounded-bl-xl rounded-br-xl rounded-tr-xl bg-neutral-300 dark:bg-neutral-700">
          {question}
        </p>
      </div>
      <div id={ansId} className="flex flex-row-reverse">
        {answered ? (
          <p
            className={classNames(
              'p-4 m-0 text-neutral-900 dark:text-neutral-200 rounded-tl-xl rounded-tr-xl rounded-bl-xl appearance-none bg-teal-100 dark:bg-teal-700',
            )}
          >
            {isArray(field.value) ? (field.value.length ? field.value.join(', ') : 'None') : field.value}
          </p>
        ) : null}
      </div>
    </section>
  )
}
