import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useContext, useState } from 'react'
import { Edge, isEdge, Node } from 'react-flow-renderer/nocss'
import { ElementsContext, getAncestors } from 'src/common/utils'
import { ConditionType, EdgeMeta, EqualToCondition, NodeMeta } from 'src/types'
import { v4 } from 'uuid'

import { ConditionsType } from '../edge-details/conditions-type'
import { EdgeConditionsDetails } from '../edge-details/edge-conditions-details'

interface EdgeDetailsProps {
  elementId: string
}

export const EdgeDetails: FC<EdgeDetailsProps> = ({ elementId }) => {
  const { elements, setElements } = useContext(ElementsContext)
  const edgeElement = elements.find(e => e.id === elementId) as Edge<EdgeMeta>

  const [strict, setStrict] = useState(edgeElement?.data?.condition?.type === 'loose' ? false : true)

  if (!edgeElement || !isEdge(edgeElement)) return null

  const edgeCondition = edgeElement?.data?.condition
  const conditions = edgeCondition?.conditions || []

  const sourceElement = elements.find(e => e.id === edgeElement.target) as Node<NodeMeta>
  const hasAncestors = getAncestors(elements, sourceElement).length > 0
  const conditionType = strict ? 'strict' : 'loose'

  const updateConditionType = (type: 'strict' | 'loose') => {
    setElements(currentElements =>
      currentElements.map(currentElement => {
        if (currentElement.id !== edgeElement.id) return currentElement
        if (isEdge(currentElement)) {
          const elementData = currentElement.data || {}
          return {
            ...currentElement,
            data: {
              ...elementData,
              condition: {
                ...elementData.condition,
                type,
                conditions: elementData.condition?.conditions || [],
              },
            },
          }
        }
        return currentElement
      }),
    )
  }
  const addCondition = () => {
    setElements(currentElements => {
      return currentElements.map(currentElement => {
        if (currentElement.id !== edgeElement.id) return currentElement
        if (isEdge(currentElement)) {
          const newCondition: EqualToCondition = {
            id: v4(),
            source: edgeElement.source,
            type: ConditionType.EqualTo,
            value: '',
          }
          const elementData = currentElement.data || {}
          return {
            ...currentElement,
            data: {
              ...elementData,
              condition: {
                type: elementData.condition?.type || 'strict',
                conditions: [...conditions, newCondition],
              },
            },
          }
        }
        return currentElement
      })
    })
  }
  const toggleStrict = () => {
    const newType = strict ? 'loose' : 'strict'
    updateConditionType(newType)
    setStrict(!strict)
  }
  if (!hasAncestors) return null
  return (
    <div className="py-4">
      <div className="px-3">
        <div className="flex items-center justify-between py-2">
          <h5 className="space-x-2 text-neutral-900 dark:text-neutral-400">Edge conditions</h5>
          <button className="p-2 appearance-none" onClick={addCondition}>
            <FontAwesomeIcon
              icon={faPlus}
              className="w-4 h-4 text-neutral-700 dark:text-neutral-400 hover:text-neutral-900 dark:hover:text-neutral-200"
            />
          </button>
        </div>
        <div className="space-y-2">
          {conditions.map((condition, index) => {
            if (!sourceElement) return null
            return (
              <div key={condition.id} className="space-y-2">
                <EdgeConditionsDetails condition={condition} sourceElement={sourceElement} element={edgeElement} />
                {conditions.length - 1 !== index ? (
                  <ConditionsType conditionType={conditionType} toggleConditionType={toggleStrict} />
                ) : null}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
