import { Edge, Node } from 'react-flow-renderer/nocss'
import { MachineConfig, State } from 'xstate'

export type TEvent =
  | { type: 'NEXT'; key: string; value?: string; values?: string[] }
  | { type: 'PREV'; key: string }
  | { type: 'RESET' }

export enum FormInterface {
  CUI = 'CUI',
  FUI = 'FUI',
}

export type TContext = {
  answers: Record<string, unknown>
  interface: FormInterface
}

export type MachineStates = MachineConfig<TContext, any, TEvent>['states']

export type MachineState = State<
  TContext,
  TEvent,
  any,
  {
    value: any
    context: TContext
  }
>

export enum FlowElementType {
  Initial = 'Initial',
  SelectableEdge = 'SelectableEdge',
  YesNoQn = 'YesNoQn',
  DropdownQn = 'DropdownQn',
  MultiSelectQn = 'MultiSelectQn',
  ChecklistQn = 'ChecklistQn',
  TextInputQn = 'TextInputQn',
  EmailInputQn = 'EmailInputQn',
  PhoneInputQn = 'PhoneInputQn',
  NumberInputQn = 'NumberInputQn',
  DateInputQn = 'DateInputQn',
  TemplateQns = 'TemplateQns',
  SectionTitle = 'SectionTitle',
  SectionBody = 'SectionBody',
  PrescriptionsQn = 'PrescriptionsQn',
  ConditionsQn = 'ConditionsQn',
  Approved = 'Approved',
  Declined = 'Declined',
  Signature = 'Signature',
  GroupQns = 'GroupQns',
}
export interface DragDropBaseData {
  type: FlowElementType
}

export interface TemplateDragDropData extends DragDropBaseData {
  type: FlowElementType.TemplateQns
  id: string
  name: string
  title: string
  question?: undefined
  entityId?: string // The original element id from the backend
}

export function isTemplateData(data?: DragDropBaseData): data is TemplateDragDropData {
  if (!data) return false
  return data.type === FlowElementType.TemplateQns
}

export type DragDropData = TemplateDragDropData | DragDropBaseData
export interface BaseMeta {
  id: string
  type: FlowElementType
  question?: string
}

export interface DropdownMeta extends BaseMeta {
  options?: string[]
}

export interface MultiSelectMeta extends BaseMeta {
  options?: string[]
}

export type Outcome = 'approved' | 'declined' | 'review'
export type ExclusionsListItem = {
  items: string[]
  outcome: Outcome
}
export interface ExclusionsMeta extends BaseMeta {
  options?: Outcome[]
  exclusionsList?: ExclusionsListItem[]
  listSource?: string
}

export interface RangeMeta extends BaseMeta {
  min?: number
  max?: number
}

export function isRangeMeta(meta: NodeMeta): meta is RangeMeta {
  return (meta as RangeMeta).min !== undefined || (meta as RangeMeta).max !== undefined
}

export interface DateRangeMeta extends BaseMeta {
  min?: string
  max?: string
}

export function isDateRangeMeta(meta: NodeMeta): meta is DateRangeMeta {
  return (meta as DateRangeMeta).min !== undefined || (meta as DateRangeMeta).max !== undefined
}

export interface InputMeta extends BaseMeta {
  inputType: string
}

export enum ConditionType {
  Every = 'Every',
  Some = 'Some',
  SomeOf = 'someOf',
  EqualTo = 'equalTo',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
}
export interface BaseCondition {
  id: string
  source: string
  type: ConditionType
}
export interface SomeOfCondition extends BaseCondition {
  type: ConditionType.SomeOf
  values: string[]
}

export interface EqualToCondition extends BaseCondition {
  type: ConditionType.EqualTo
  value: string
}

export interface GreaterThanCondition extends BaseCondition {
  type: ConditionType.GreaterThan
  value: string
}

export interface LessThanCondition extends BaseCondition {
  type: ConditionType.LessThan
  value: string
}

export function isEqualToEdge(edge: Edge): edge is Edge<EqualToCondition> {
  return (edge.data?.condition as EqualToCondition)?.type === ConditionType.EqualTo
}

export type EdgeCompareCondition = EqualToCondition | GreaterThanCondition | LessThanCondition
export type EdgeCondition = EdgeCompareCondition | SomeOfCondition
export type EdgeConditionsArray = EdgeCondition[]
export interface EdgeConditions {
  type: 'strict' | 'loose'
  conditions: EdgeConditionsArray
}

export interface EdgeMeta {
  condition?: EdgeConditions
}

export type BaseNodeMeta =
  | InputMeta
  | DropdownMeta
  | MultiSelectMeta
  | TemplateDragDropData
  | RangeMeta
  | ExclusionsMeta

export interface GroupMeta extends BaseMeta {
  selected?: string
  title?: string
  desc?: string
  questions?: BaseNodeMeta[]
}

export function isGroupData(data?: NodeMeta): data is GroupMeta {
  if (!data) return false
  return (data as GroupMeta).questions !== undefined
}

export type NodeMeta = BaseNodeMeta | GroupMeta

export type FlowNodes = Node<NodeMeta>[]

export type FlowElements = Array<Node<NodeMeta> | Edge<EdgeMeta>>

export type NodeType = NodeMeta['type']
