import React, { FC } from 'react'
import { useBubbleEntry } from 'src/hooks/use-bubble-entry'
import { useFormikField } from 'src/hooks/use-formik-field'

interface SignatureBubbleProps {
  id: string
  question: string
}

export const SignatureBubble: FC<Pick<SignatureBubbleProps, 'id' | 'question'>> = ({ id, question }) => {
  const { field, meta } = useFormikField(id)

  const answered = meta.touched && field.value !== undefined

  const qnId = `qn-${id}`
  const ansId = `ans-${id}`

  useBubbleEntry(qnId)
  useBubbleEntry(ansId)

  return (
    <section id={qnId} className="px-4 my-4 space-y-2">
      <p className="inline-block p-4 m-0 rounded-tr-lg rounded-bl-lg rounded-br-lg text-neutral-900 dark:text-neutral-200 bg-neutral-300 dark:bg-neutral-700">
        {question}
      </p>
      {answered ? (
        <div id={ansId} className="flex flex-row-reverse bg-white dark:bg-teal-700">
          <div className="w-1/2">
            <img alt="signature" src={`data:image/svg+xml;base64,${btoa(field.value)}`} className="w-full h-16" />
          </div>
        </div>
      ) : null}
    </section>
  )
}
