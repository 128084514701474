import React, { FC } from 'react'
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer/nocss'

export const InitialNode: FC<NodeComponentProps> = () => {
  return (
    <div className="flex items-center justify-center w-64 py-4 bg-green-600 border border-green-600 rounded-full shadow dark:bg-green-700">
      <span className="font-medium text-center text-white uppercase">start</span>
      <div className="absolute bottom-0 z-10 flex items-center justify-center p-2">
        <Handle
          type="source"
          position={Position.Bottom}
          className="w-3 h-3 border-2 bottom-[-6px] border-white dark:border-green-400 rounded-full bg-green-700"
        />
      </div>
    </div>
  )
}
