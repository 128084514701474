import { apolloClient } from 'src/apollo-client'
import {
  CreateFormDesignDocument,
  CreateFormDesignMutation,
  CreateFormDesignMutationVariables,
  DesignType,
  GetFormDesignsDocument,
} from 'src/graphql-schema'

export async function createFormDesign(values: CreateFormDesignMutationVariables, type?: DesignType) {
  try {
    const { data } = await apolloClient.mutate<CreateFormDesignMutation, CreateFormDesignMutationVariables>({
      mutation: CreateFormDesignDocument,
      variables: values,
      refetchQueries: [
        { query: GetFormDesignsDocument, variables: type ? { data: { filter: { type } } } : { data: {} } },
      ],
    })
    return data?.createFormDesign || null
  } catch (error) {
    return null
  }
}
