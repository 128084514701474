import cc from 'classcat'
import React, { CSSProperties, memo } from 'react'

interface ThumbnailNodeProps {
  x: number
  y: number
  width: number
  height: number
  shapeRendering: string
  isTemplate?: boolean
  isInitial?: boolean
}

const ThumbnailNode = ({ x, y, width, height, shapeRendering, isTemplate, isInitial }: ThumbnailNodeProps) => {
  return (
    <rect
      className={cc([
        isInitial
          ? 'dark:fill-green-800 fill-green-400 stroke-green-500 dark:stroke-green-900'
          : isTemplate
          ? 'dark:fill-purple-800 fill-purple-400 stroke-purple-500 dark:stroke-purple-900'
          : 'dark:fill-sky-800 dark:stroke-sky-900 fill-sky-400 stroke-sky-500',
      ])}
      x={x}
      y={y}
      rx={isInitial ? height / 2 : 5}
      ry={isInitial ? width / 2 : 5}
      width={width}
      height={height}
      strokeWidth={4}
      shapeRendering={shapeRendering}
    />
  )
}

ThumbnailNode.displayName = 'ThumbnailNode'

export default memo(ThumbnailNode)
