import React, { FC } from 'react'
import { NodeComponentProps } from 'react-flow-renderer/nocss'
import { classNames } from 'src/common/utils'
import { TargetHandle } from 'src/components/target-handle'

export const Declined: FC<NodeComponentProps> = ({ id, selected, data = {}, children }) => {
  return (
    <div
      className={classNames(
        'border rounded-lg w-80 pb-4',
        selected
          ? 'shadow-lg shadow-rose-600/20 border-rose-600 ring-1 ring-rose-400 bg-rose-50 dark:bg-rose-900'
          : 'bg-white dark:bg-neutral-800 shadow border-rose-400',
      )}
    >
      <TargetHandle />
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-1 text-neutral-900 dark:text-rose-400">
          {data.name ? (
            <span>{data.name}</span>
          ) : (
            <>
              <span className="hidden">{`Q-${id} :`}</span>
              <span className="capitalize text-rose-700 dark:text-rose-300">Declined</span>
            </>
          )}
        </div>
      </div>
      <div className="px-4 py-2">
        <p
          className={classNames(
            'line-clamp-4',
            data.question
              ? ' text-rose-900 dark:text-rose-300'
              : 'text-neutral-600 dark:text-neutral-400 font-light italic',
          )}
        >
          {data.question || `Declined message`}
        </p>
      </div>
      {children}
    </div>
  )
}
