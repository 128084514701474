import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import React, { ChangeEvent, FC, useContext, useState } from 'react'
import { Edge, isEdge, Node } from 'react-flow-renderer/nocss'
import { ElementsContext } from 'src/common/utils'
import { SelectInput } from 'src/components/select-input'
import { ConditionType, EdgeConditions, EdgeMeta, NodeMeta, SomeOfCondition } from 'src/types'
import useDeepCompareEffect from 'use-deep-compare-effect'

interface SomeOfEdgeDetailsProps {
  element: Edge<EdgeMeta>
  sourceElement: Node<NodeMeta>
  condition: SomeOfCondition
}

export const SomeOfEdgeDetails: FC<SomeOfEdgeDetailsProps> = ({ element, sourceElement, condition }) => {
  const { elements, setElements } = useContext(ElementsContext)
  const [values, setValues] = useState<string[]>(condition.values || [])

  const otherSourceEdges = elements.filter(els => {
    if (!isEdge(els)) return false
    if (els.id === element.id) return false
    return els.source === sourceElement.id
  })
  const otherConditions = compact(flatten(map(otherSourceEdges, edge => get(edge, 'data.condition.values', []))))
  const options = get(sourceElement, 'data.options', []).filter((option: string) => !otherConditions.includes(option))

  const addNewValue = () => {
    setValues(currentValues => [...currentValues, ''])
  }

  const removeValueAtIndex = (index: number) => () => {
    setValues(currentValues => currentValues.filter((_, i) => i !== index))
  }

  const updateValueAtIndex = (index: number) => (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setValues(currentValues => {
      const updatedValues = [...currentValues]
      updatedValues[index] = value
      return updatedValues
    })
  }
  const updateCondition = (values: string[]) => {
    setElements(currentElements => {
      const updatedConditions = (element.data?.condition?.conditions || []).map(cond => {
        if (cond.id === condition.id) {
          return {
            id: condition.id,
            source: element.source === sourceElement.id ? 'event' : sourceElement.id,
            type: ConditionType.SomeOf,
            values,
          } as SomeOfCondition
        }
        return cond
      })
      const updatedCondition: EdgeConditions = element.data?.condition
        ? { ...element.data.condition, conditions: updatedConditions }
        : { type: 'strict', conditions: updatedConditions }

      const updatedEdge: Edge<EdgeMeta> = {
        ...element,
        data: {
          ...element.data,
          condition: updatedCondition,
        },
      }

      return currentElements.filter(e => e.id !== element.id).concat([updatedEdge])
    })
  }

  const uniqValues = compact(uniq(values))
  useDeepCompareEffect(() => {
    updateCondition(uniqValues)
  }, [{ values: uniqValues }])

  return (
    <div className="text-xs">
      <div className="flex items-center justify-between py-2">
        <div>Add matching options</div>
        <button className="p-2 appearance-none" onClick={addNewValue}>
          <FontAwesomeIcon icon={faPlus} className=" text-neutral-500" size="lg" />
        </button>
      </div>
      <div className="space-y-2">
        {values.map((value, index) => (
          <div key={value} className="flex items-center space-x-2">
            <SelectInput
              name={value}
              value={value}
              onChange={updateValueAtIndex(index)}
              options={options}
              className="text-input"
              showNone
            ></SelectInput>
            <button className="p-2 appearance-none" onClick={removeValueAtIndex(index)}>
              <FontAwesomeIcon icon={faMinus} className=" text-neutral-500 dark:text-neutral-400" size="lg" />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
