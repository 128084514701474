import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import React, { DragEventHandler, FC, KeyboardEventHandler, MouseEventHandler, useCallback, useContext } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Handle, Node, NodeComponentProps, Position, useStoreActions } from 'react-flow-renderer/nocss'
import { classNames, ElementsContext, nodeData, nodeTitles } from 'src/common/utils'
import { TargetHandle } from 'src/components/target-handle'
import { BaseMeta, BaseNodeMeta, DragDropData, FlowElementType, GroupMeta } from 'src/types'
import { v4 } from 'uuid'

const inactiveBorderClasses = ['border-neutral-200', 'dark:border-neutral-600']
interface GroupQuestionProps {
  index: number
  question: BaseMeta
  selected: boolean
  onQuestionClick: (id: string) => void
  onMouseEnter: MouseEventHandler<HTMLDivElement>
  onMouseLeave: MouseEventHandler<HTMLDivElement>
}

function GroupQuestion({ question, index, selected, onQuestionClick, onMouseEnter, onMouseLeave }: GroupQuestionProps) {
  const handleQuestionClick = () => onQuestionClick(question.id)
  const handleQuestionKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    event.code === 'Enter' && onQuestionClick(question.id)
  }
  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          set(provided.draggableProps, 'style.left', get(provided.draggableProps, 'style.offsetLeft'))
          set(provided.draggableProps, 'style.top', get(provided.draggableProps, 'style.offsetTop'))
        }
        return (
          <div
            role="button"
            tabIndex={index}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={handleQuestionClick}
            onKeyDown={handleQuestionKeyDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classNames(
              'flex items-center space-x-1 p-2 border mb-2 bg-white dark:bg-neutral-800',
              selected ? 'border-sky-600 ring-1 ring-sky-600' : 'border-neutral-200  dark:border-neutral-600',
            )}
          >
            <div className="">{question.question || nodeTitles[question.type]}</div>
          </div>
        )
      }}
    </Draggable>
  )
}
export const GroupQns: FC<NodeComponentProps> = props => {
  const { selected, data, id } = props
  const { elements, setElements } = useContext(ElementsContext)
  const setSelectedElements = useStoreActions(actions => actions.setSelectedElements)
  const groupElement = elements.find(e => e.id === id) as Node<GroupMeta>

  const updateQuestion = useCallback(
    (value: BaseNodeMeta) => {
      if (!groupElement.data) return
      const updatedNode: Node<GroupMeta> = {
        ...groupElement,
        data: {
          ...groupElement.data,
          selected: value.id,
          questions: [...(groupElement.data.questions || []), value],
        },
      }
      setElements(currentElements => {
        return currentElements.filter(e => e.id !== groupElement.id).concat([updatedNode])
      })
      setTimeout(() => setSelectedElements([updatedNode]), 0)
    },
    [groupElement, setElements, setSelectedElements],
  )
  const updateSelectedQuestion = useCallback(
    (questionId: string) => {
      if (!groupElement.data) return
      const updatedNode: Node<GroupMeta> = {
        ...groupElement,
        data: {
          ...groupElement.data,
          selected: questionId,
        },
      }
      setElements(currentElements => {
        return currentElements.filter(e => e.id !== groupElement.id).concat([updatedNode])
      })
      setTimeout(() => setSelectedElements([updatedNode]), 0)
    },
    [groupElement, setElements, setSelectedElements],
  )

  const onDragOver: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const onDrop: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    event.stopPropagation()

    const data = JSON.parse(event.dataTransfer.getData('application/form-builder')) as DragDropData
    const { type } = data

    if (type === FlowElementType.GroupQns) return null // nested groups are not supported.
    if (type === FlowElementType.TemplateQns) return null // templates inside a group are not supported.
    const nodeMetadata = nodeData[type] || {}
    const questionData = { id: v4(), type, ...cloneDeep(nodeMetadata) } as BaseNodeMeta
    updateQuestion(questionData)
  }

  const setDraggable = (draggable: boolean) => {
    setElements(currentElements => {
      return currentElements.map(element => {
        if (element.id !== id) return element
        return {
          ...element,
          draggable,
        }
      })
    })
  }
  const onMouseEnter = () => setDraggable(false) // TODO: support touch devices
  const onMouseLeave = () => setDraggable(true)

  const onDragListEnd = (result: DropResult) => {
    if (!result.destination) return // TODO: remove question
    if (!data) return
    const { source, destination, draggableId } = result
    if (source.index === destination.index && destination.droppableId === source.droppableId) return
    const newQuestions = [...(data.questions || [])]
    const [removed] = newQuestions.splice(source.index, 1)
    newQuestions.splice(destination.index, 0, removed)
    const updatedNode: Node<GroupMeta> = {
      ...groupElement,
      data: {
        ...data,
        selected: draggableId,
        questions: newQuestions,
      },
    }
    setElements(currentElements => currentElements.filter(e => e.id !== groupElement.id).concat([updatedNode]))
  }

  const questions = data.questions || []
  const emptyGroup = questions.length === 0

  return (
    <div
      className={classNames(
        'border rounded-sm w-80',
        selected
          ? 'shadow-lg shadow-sky-600/20 border-sky-600 ring-1 ring-sky-600 bg-sky-50 dark:bg-neutral-900'
          : 'bg-white dark:bg-neutral-800 shadow border-neutral-200 dark:border-neutral-900',
      )}
    >
      <TargetHandle />
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-1 text-neutral-900 dark:text-neutral-400">
          {data.name ? (
            <span>{data.name}</span>
          ) : (
            <>
              <span className="hidden">{`Q-${id} :`}</span>
              <span className="capitalize">Group</span>
            </>
          )}
        </div>
      </div>
      <div className="px-4 py-2">
        {data.title ? (
          <div className="mb-2">
            <span className="capitalize">{data.title}</span>
          </div>
        ) : null}
        <DragDropContext onDragEnd={onDragListEnd}>
          <Droppable droppableId={id}>
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                onDragOver={onDragOver}
                onDrop={onDrop}
                className={classNames(
                  'py-4',
                  emptyGroup ? 'border-2' : 'border-0',
                  'text-center text-neutral-900 dark:text-neutral-100 border-dashed rounded',
                  inactiveBorderClasses.join(' '),
                )}
              >
                {emptyGroup ? <span>Drop questions here</span> : null}
                {questions.map((question: BaseMeta, index: number) => (
                  <GroupQuestion
                    key={question.id}
                    index={index}
                    question={question}
                    selected={!!selected && data.selected === question.id}
                    onQuestionClick={updateSelectedQuestion}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="relative z-10 flex items-center justify-center px-2 pb-2">
        <Handle
          type="source"
          position={Position.Bottom}
          className="w-3 h-3 border-2 bottom-[-6px] border-white dark:border-neutral-900 rounded-full bg-sky-700 dark:bg-sky-800"
        />
      </div>
    </div>
  )
}
