import { inspect } from '@xstate/inspect'
import { Formik } from 'formik'
import React, { FC, useMemo } from 'react'
import { inspectStateCharts } from 'src/common/config'
import { elementsToInitialValues, elementsToStates, elementsToYupSchema } from 'src/common/transformers'
import { compareElements, machineDebug } from 'src/common/utils'
import { DesignType, useGetFormDesignsQuery } from 'src/graphql-schema'
import { useMemoCompare } from 'src/hooks/use-memo-compare'
import { FlowElements, FlowElementType, FormInterface } from 'src/types'
import { v4 } from 'uuid'
import { createMachine } from 'xstate'

import { FormWizard } from './form-preview/form-wizard'
import { actions, guards } from './form-preview/machine-options'

const machineOptions = { guards, actions }
interface FormPreviewProps {
  elements: FlowElements
  title: string
  desc: string
}

if (inspectStateCharts) {
  inspect({
    url: 'https://statecharts.io/inspect',
    iframe: false,
  })
}

export const FormPreview: FC<FormPreviewProps> = ({ elements, title, desc }) => {
  const { data, loading } = useGetFormDesignsQuery({
    variables: { data: { filter: { type: DesignType.Template } } },
  })
  const formElements = useMemoCompare<FlowElements>(elements, compareElements)

  const formMachine = useMemo(() => {
    const templates = data?.getFormDesigns || []
    const stateConfig = elementsToStates(formElements || [], templates)
    const machineConfig = {
      id: v4(),
      context: { answers: {}, interface: FormInterface.CUI },
      initial: FlowElementType.Initial,
      meta: {
        title,
        desc,
      },
      on: {
        RESET: FlowElementType.Initial,
      },
      states: stateConfig,
    }
    machineDebug(machineConfig)
    return createMachine(machineConfig, machineOptions)
  }, [formElements, desc, title, data?.getFormDesigns])

  const initialValues = elementsToInitialValues(elements)
  const validationSchema = elementsToYupSchema(elements)

  if (loading) return <div>Loading...</div>
  return (
    <Formik onSubmit={console.log} initialValues={initialValues} validationSchema={validationSchema}>
      <FormWizard machine={formMachine} key={formMachine.id} title={title} desc={desc} />
    </Formik>
  )
}
