import React, { DragEventHandler, FC } from 'react'

interface DragDropFileProps {
  handleFile: (file: File) => void
}
export const DragDropFile: FC<DragDropFileProps> = ({ children, handleFile }) => {
  const suppress: DragEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
    event.preventDefault()
  }
  const handleDrop: DragEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <div onDrop={handleDrop} onDragEnter={suppress} onDragOver={suppress}>
      {children}
    </div>
  )
}
