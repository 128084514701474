import React, { FC } from 'react'
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer/nocss'
import { classNames } from 'src/common/utils'
import { TargetHandle } from 'src/components/target-handle'

interface BaseQuestionProps extends NodeComponentProps {
  label: string
  hideSourceHandle?: boolean
}

export const Signature: FC<BaseQuestionProps> = ({ selected, data, hideSourceHandle = false, children }) => {
  return (
    <div
      className={classNames(
        'bg-white dark:bg-neutral-800 border dark:border-neutral-900 rounded-sm w-80',
        selected ? 'shadow-lg border-sky-600 ring-1 ring-sky-600 bg-sky-100' : 'shadow border-neutral-200',
      )}
    >
      <TargetHandle />
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-1 text-neutral-900 dark:text-neutral-400">
          <span className="capitalize">signature</span>
        </div>
      </div>
      <div className="px-4 py-2">
        <p
          className={classNames(
            'line-clamp-4 leading-relaxed',
            data.text ? 'text-neutral-800 dark:text-neutral-300' : 'text-neutral-400 italic',
          )}
        >
          {data.text || `Declaration text`}
        </p>
      </div>
      {children}
      {hideSourceHandle ? null : (
        <div className="relative z-10 flex items-center justify-center p-2">
          <Handle
            type="source"
            position={Position.Bottom}
            className="w-3 h-3 border-2 bottom-[-6px] border-white dark:border-neutral-900 rounded-full bg-sky-700"
          />
        </div>
      )}
    </div>
  )
}
