import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { classNames } from 'src/common/utils'

export const ApprovedControls: FC = () => {
  const { submitForm } = useFormikContext()

  const submitAnswer = () => {
    requestAnimationFrame(() => submitForm())
  }
  return (
    <div className="flex">
      <button
        onClick={submitAnswer}
        className={classNames(
          'p-4 flex-1 text-white rounded appearance-none bg-sky-600 hover:bg-sky-800 flex items-center justify-center text-center',
        )}
      >
        Apply Now
      </button>
    </div>
  )
}
