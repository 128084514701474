import dagre, { GraphLabel } from 'dagre'
import { useCallback, useContext } from 'react'
import { isNode, Node, Position, useStoreState } from 'react-flow-renderer/nocss'
import { ElementsContext, isBrowser } from 'src/common/utils'
import { FlowElements, NodeMeta } from 'src/types'

export const prettifyElements = (elements: FlowElements, nodes: Node<NodeMeta>[], layoutOptions?: dagre.GraphLabel) => {
  if (!isBrowser()) return elements
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  dagreGraph.setGraph(graphOptions)
  elements.forEach(el => {
    if (isNode(el)) {
      const node = nodes.find(n => n.id === el.id)
      if (node) {
        dagreGraph.setNode(el.id, { width: node.__rf.width, height: node.__rf.height })
      }
    } else {
      dagreGraph.setEdge(el.source, el.target)
    }
  })
  dagre.layout(dagreGraph, layoutOptions)
  const laidOutElements = elements.map(el => {
    if (isNode(el)) {
      const newEl = { ...el }
      const node = nodes.find(n => n.id === newEl.id)
      const nodeWithPosition = dagreGraph.node(newEl.id)
      newEl.targetPosition = Position.Top
      newEl.sourcePosition = Position.Bottom

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      if (node) {
        newEl.position = {
          x: nodeWithPosition.x - node.__rf.width / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - node.__rf.height / 2,
        }
      }
      return newEl
    }
    return el
  })
  return laidOutElements
}

const graphOptions: GraphLabel = { rankdir: 'TB', nodesep: 100, edgesep: 50, ranksep: 100 }
export const useLayoutGraph = () => {
  const { elements, setElements } = useContext(ElementsContext)
  const nodes = useStoreState(state => state.nodes)
  const layoutElements = useCallback(
    (layoutOptions?: dagre.GraphLabel) => {
      const laidOutElements = prettifyElements(elements, nodes, layoutOptions)
      setElements(laidOutElements)
    },
    [nodes, elements, setElements],
  )
  return layoutElements
}
