import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useState } from 'react'
import { classNames } from 'src/common/utils'
import { useFormikField } from 'src/hooks/use-formik-field'

interface DropdownQnProps {
  id: string
  type: string
  placeholder: string
  onAnswered: (answer: string) => void
}

export const TextInputControls: FC<DropdownQnProps> = ({ id, onAnswered, type = 'text', placeholder }) => {
  const { field, helpers } = useFormikField(id)
  const [value, setValue] = useState(field.value || '')
  const [error, setError] = useState<string>()

  const submitAnswer = async () => {
    const res = (await helpers.setValue(value)) as unknown as Record<string, string>
    const error = res[id]
    if (error) return setError(error)
    setError(undefined)
    helpers.setTouched(true)
    requestAnimationFrame(() => onAnswered(value))
  }
  return (
    <div className="flex space-x-2">
      <input
        type={type}
        name={id}
        id={`${id}-input`}
        value={value}
        onChange={e => setValue(e.target.value)}
        className={classNames(
          'block w-full rounded-md shadow-sm appearance-none text-sm dark:bg-neutral-800 dark:text-neutral-200',
          error
            ? 'border-rose-500 focus:ring-rose-500 focus:border-rose-500'
            : 'border-neutral-300 dark:border-neutral-700 focus:ring-sky-500 focus:border-sky-500',
        )}
        placeholder={placeholder}
      />
      <button
        onClick={submitAnswer}
        className="flex items-center justify-center flex-1 p-4 text-center rounded appearance-none text-neutral-900 dark:text-neutral-200 bg-neutral-400 hover:bg-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-900"
      >
        <FontAwesomeIcon icon={faPaperPlaneTop} className="text-white dark:text-neutral-200" size="lg" />
      </button>
    </div>
  )
}
