import React, { HTMLAttributes, memo } from 'react'
import { getRectOfNodes, Rect, useStoreState } from 'react-flow-renderer/nocss'

import ThumbnailNode from './thumbnail/thumbnail-node'
import { getBoundsOfRects } from './thumbnail/utils'

export interface ThumbnailProps extends HTMLAttributes<SVGSVGElement> {
  isTemplate?: boolean
}

declare const window: any

const Thumbnail = ({ isTemplate }: ThumbnailProps) => {
  const containerWidth = useStoreState(s => s.width)
  const containerHeight = useStoreState(s => s.height)
  const nodes = useStoreState(s => s.nodes)

  const hasNodes = nodes && nodes.length
  const bb = getRectOfNodes(nodes)
  const viewBB: Rect = {
    x: 0,
    y: 0,
    width: containerWidth,
    height: containerHeight,
  }
  const boundingRect = hasNodes ? getBoundsOfRects(bb, bb) : viewBB
  const shapeRendering = typeof window === 'undefined' || !!window.chrome ? 'crispEdges' : 'geometricPrecision'

  const SVGElement = (
    <svg viewBox={`0 0 ${boundingRect.width} ${boundingRect.height}`}>
      {nodes
        .filter(node => !node.isHidden)
        .map(node => {
          return (
            <ThumbnailNode
              key={node.id}
              x={node.__rf.position.x}
              y={node.__rf.position.y}
              width={node.__rf.width}
              height={node.__rf.height}
              shapeRendering={shapeRendering}
              isTemplate={isTemplate}
              isInitial={node.type?.toLowerCase() === 'initial'}
            />
          )
        })}
    </svg>
  )
  return SVGElement
}

Thumbnail.displayName = 'Thumbnail'

export default memo(Thumbnail)
