import { Router } from '@reach/router'
import React from 'react'
import { Layout } from 'src/components/layout'
import { useDarkMode } from 'src/hooks/use-dark-mode'
import { Editor } from 'src/screens/editor'
import { Home } from 'src/screens/home'

export default function HomePage() {
  useDarkMode()
  return (
    <Layout>
      <Router basepath="/designs">
        <Editor path="/:id/editor" />
        <Home path="/" />
      </Router>
    </Layout>
  )
}
