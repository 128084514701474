import { faPaperPlaneTop, faSort } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@headlessui/react'
import uniq from 'lodash/uniq'
import React, { FC, Fragment, useState } from 'react'
import { useFormikField } from 'src/hooks/use-formik-field'
import { useToggle } from 'src/hooks/use-toggle'

interface MultiSelectQnProps {
  id: string
  options: string[]
  onAnswered: (answer: string[]) => void
}

export const MultiSelectControls: FC<MultiSelectQnProps> = ({ id, onAnswered, options = [] }) => {
  const { helpers } = useFormikField(id)
  const [open, toggleOpen] = useToggle(false)
  const [values, setValues] = useState<string[]>([])

  const addToValues = (value: string) => {
    setValues(prevValues => uniq([...prevValues, value]))
  }
  const removeFromValues = (value: string) => {
    setValues(prevValues => prevValues.filter(v => v !== value))
  }
  const submitAnswer = () => {
    helpers.setValue(values)
    helpers.setTouched(true)
    helpers.setError(undefined)
    requestAnimationFrame(() => onAnswered(values))
  }
  return (
    <div className="">
      <div className="">
        <div className="relative">
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute bottom-0 z-10 w-full py-1 mb-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-neutral-700 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div className="flex items-center justify-between px-4 border-b border-b-neutral-200 dark:border-b-neutral-600">
                <span className="text-neutral-600 dark:text-neutral-300">Select all that apply</span>
                <button
                  onClick={toggleOpen}
                  className="flex items-center justify-center p-2 pr-0 font-semibold text-center appearance-none text-sky-600"
                >
                  Done
                </button>
              </div>
              <div className="pb-2 overflow-y-scroll max-h-60">
                {options.map((option, index) => (
                  <div key={option} className="relative flex items-start px-4 py-2 space-x-3">
                    <div className="flex items-center h-5">
                      <input
                        id={`option-${index}`}
                        name={`option-${index}`}
                        value={option}
                        checked={values.includes(option)}
                        onChange={() => {
                          if (values.includes(option)) {
                            removeFromValues(option)
                          } else {
                            addToValues(option)
                          }
                        }}
                        type="checkbox"
                        className="w-5 h-5 rounded border-neutral-300 dark:border-neutral-900 dark:bg-neutral-800 text-sky-600 focus:ring-sky-500"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <label
                        htmlFor={`option-${index}`}
                        className="font-medium select-none text-neutral-700 dark:text-neutral-300"
                      >
                        {option}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Transition>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={toggleOpen}
            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-md shadow-sm cursor-default dark:bg-neutral-800 dark:border-neutral-700 border-neutral-300 focus:outline-none focus:ring-1 focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm"
          >
            <span className="block truncate">Select all that apply</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FontAwesomeIcon icon={faSort} className="w-5 h-5 text-neutral-400" aria-hidden="true" />
            </span>
          </button>
          <button
            onClick={submitAnswer}
            className="flex items-center justify-center flex-1 p-4 text-center rounded appearance-none text-neutral-900 dark:text-neutral-200 bg-neutral-400 hover:bg-neutral-600 dark:bg-neutral-700 dark:hover:bg-neutral-900"
          >
            <FontAwesomeIcon icon={faPaperPlaneTop} className="text-white " size="lg" />
          </button>
        </div>
      </div>
    </div>
  )
}
