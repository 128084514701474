import { apolloClient } from '../apollo-client'
import {
  DesignType,
  GetFormDesignsDocument,
  UpdateFormDesignDocument,
  UpdateFormDesignMutation,
  UpdateFormDesignMutationVariables,
} from '../graphql-schema'

export async function updateFormDesign(values: UpdateFormDesignMutationVariables, type?: DesignType) {
  try {
    const { data } = await apolloClient.mutate<UpdateFormDesignMutation, UpdateFormDesignMutationVariables>({
      mutation: UpdateFormDesignDocument,
      variables: { ...values },
      refetchQueries: [
        { query: GetFormDesignsDocument, variables: type ? { data: { filter: { type } } } : { data: {} } },
      ],
    })
    return data?.updateFormDesign || null
  } catch (error) {
    return null
  }
}
