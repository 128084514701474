import get from 'lodash/get'
import React, { FC, useCallback, useContext, useState } from 'react'
import { Edge, Node } from 'react-flow-renderer/nocss'
import { ElementsContext } from 'src/common/utils'
import { DropdownInput, DropdownOption } from 'src/components/dropdown-input'
import { ExpandableTextarea } from 'src/components/expandable-textarea'
import { EdgeCompareCondition, EdgeConditions, EdgeMeta, NodeMeta } from 'src/types'

interface ConditionDetailsProps {
  element: Edge<EdgeMeta>
  sourceElement: Node<NodeMeta>
  condition: EdgeCompareCondition
}

export const ConditionDetails: FC<ConditionDetailsProps> = ({ element, sourceElement, condition }) => {
  const { setElements } = useContext(ElementsContext)
  const [conditionValue, setConditionValue] = useState(condition.value)

  const source = element.source === sourceElement.id ? 'event' : sourceElement.id
  const updateCondition = useCallback(
    ({ name }: DropdownOption) => {
      setElements(currentElements => {
        if (!element.data) return currentElements
        const updatedConditions = (element.data.condition?.conditions || []).map(cond => {
          if (cond.id === condition.id) {
            return {
              id: condition.id,
              source,
              type: condition.type,
              value: name,
            }
          }
          return cond
        })

        const updatedCondition: EdgeConditions = element.data.condition
          ? { ...element.data.condition, conditions: updatedConditions }
          : { type: 'strict', conditions: updatedConditions }

        const updatedEdge: Edge<EdgeMeta> = {
          ...element,
          data: {
            ...element.data,
            condition: updatedCondition,
          },
        }

        return currentElements.filter(e => e.id !== element.id).concat([updatedEdge])
      })
    },
    [source, element, setElements, condition.id, condition.type],
  )

  const options = get(sourceElement, 'data.options', []).map((option: string) => ({ id: option, name: option }))
  const selectedOption = options.find((option: DropdownOption) => option.id === condition.value)
  const name = `select-${sourceElement?.id}`

  return (
    <div className="text-xs">
      {options.length ? (
        <DropdownInput
          label="value"
          options={options}
          selectedOption={selectedOption || { id: '', name: '' }}
          setSelectedOption={updateCondition}
        />
      ) : (
        <ExpandableTextarea
          name={name}
          value={conditionValue}
          label="value"
          required
          onChange={event => {
            const value = event.target.value
            setConditionValue(value)
            if (value) updateCondition({ name: value, id: value })
          }}
          placeholder="value"
          className="text-input"
          autoComplete="off"
          spellCheck={false}
        />
      )}
    </div>
  )
}
