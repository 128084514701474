import { faDiagramProject } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fuse from 'fuse.js'
import map from 'lodash/map'
import React, { FC } from 'react'
import { DesignType, useGetFormDesignsQuery } from 'src/graphql-schema'
import { FlowElementType } from 'src/types'

import { LibraryItemProps } from './types'
import { classNames } from './utils'

interface TemplatesProps extends LibraryItemProps {
  searchTerm: string
}

export const Templates: FC<TemplatesProps> = ({ onDragStart, searchTerm }) => {
  const { data } = useGetFormDesignsQuery({
    variables: { data: { filter: { type: DesignType.Template } } },
  })
  const templates = data?.getFormDesigns || []
  let filteredTemplates = map(templates, template => template)
  if (searchTerm) {
    const fuse = new Fuse(filteredTemplates, {
      keys: ['name', 'title'],
      shouldSort: false,
    })
    filteredTemplates = fuse.search(searchTerm).map(({ item }) => item)
  }

  if (filteredTemplates.length === 0) return null

  return (
    <div>
      <h6 className="mb-2 capitalize text-neutral-900 dark:text-neutral-400">My templates</h6>
      <div className="space-y-4">
        {map(filteredTemplates, template => (
          <div
            key={template.id}
            className={classNames}
            onDragStart={event =>
              onDragStart(event, {
                type: FlowElementType.TemplateQns,
                id: template.id,
                entityId: template.id,
                name: template.name,
                title: template.title || '',
              })
            }
            draggable
          >
            <FontAwesomeIcon icon={faDiagramProject} className="w-4 h-4 text-neutral-900 dark:text-neutral-400" />
            <span className="capitalize text-neutral-900 dark:text-neutral-300">{template.name}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
